import { useState, useCallback, useEffect } from "react";

export interface SdaData {
  id: number;
  idClient: number;
  sda: string; // SDA
  schedule: string; // Horaire
  destination: string; // Destination Principale
  destinationData: string; // Valeur Destination Principale
  ringDuration: number;
  trunkTransfert: string; // Trunk Transfert
  nrDestination: string; // Sonnerie
  nrDestinationData: string; // Valeur Destination
  nrTrunkTransfert: string;
  state: boolean; // Etat
}

// Fallback data in case API fails
const fallBackSdaTableData: SdaData[] = [
  {
    id: 1,
    idClient: 9,
    sda: "651566505",
    schedule: "Ouverte",
    destination: "Paris",
    destinationData: "8001",
    ringDuration: 30,
    trunkTransfert: "Mbappé",
    nrDestination: "Maison",
    nrDestinationData: "6996",
    nrTrunkTransfert: '06 52 42 46 43',
    state: false
  },
];

// Default empty table data
const sdaTableData: SdaData[] = [];

/**
 * **Règles entrantes**:
 * A custom hook that manages inbound SDA (Direct Dialing Number) rules data, including loading and error states.
 * It fetches SDA data from an external API, using token-based authentication stored in localStorage.
 *
 * @returns {Object} - The SDA data, loading state, error message, and a setter for SDA data.
 *
 * @property {SdaData[]} sdaData - SDA data fetched from the API or fallback data in case of errors.
 * @property {Function} setSdaData - Function to manually set the SDA data.
 * @property {boolean} loading - Indicates whether data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 */
const useSda = () => {
  const [sdaData, setSdaData] = useState<SdaData[]>(sdaTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/sda', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSdaData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    sdaData,
    setSdaData,
    loading,
    error
  };
};

export default useSda;
