import { useState, useCallback, useEffect } from "react";

export interface GroupData {
  id: number;
  idClient: number;
  nameGroup: string;
  ringStrategy: string;
  internalUsers: string;
  externalNumber: string;
  trunkExternalNumber: string;
  priorityExternalInternal: string;
  ringDuration: number;
  state: boolean;
}

const fallBackGroupTableData: GroupData[] = [
  {
    id: 1,
    idClient: 9,
    nameGroup: "Support Team",
    ringStrategy: "sequential",
    internalUsers: "1001,1002",
    externalNumber: "0123456789",
    trunkExternalNumber: "MainOffice",
    priorityExternalInternal: "internal",
    ringDuration: 45,
    state: true
  },
];

// Default empty group data
const groupTableData: GroupData[] = [];

/**
 * **Groupes d'appels**:
 * A custom hook that manages call group data, loading state, and error state.
 * It fetches group data (such as ring strategies, internal/external users, and priorities) from an external API and handles authentication via a token stored in localStorage.
 *
 * @returns {Object} - The group data, loading state, error message, and setter for group data.
 *
 * @property {GroupData[]} groupData - Call group data fetched from the API or fallback data in case of errors.
 * @property {Function} setGroupData - The function to manually set the group data.
 * @property {boolean} loading - Indicates whether data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * // Example usage of useGroup in a component
 * const { groupData, loading, error } = useGroup();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <GroupList data={groupData} />;
 */
const useGroup = () => {
  const [groupData, setGroupData] = useState<GroupData[]>(groupTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/group', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setGroupData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // setGroupData(fallBackGroupTableData); // Use fallback data in case of error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    groupData,
    setGroupData,
    loading,
    error
  };
};

export default useGroup;
