import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHourglassHalf, faStopCircle } from "@fortawesome/free-regular-svg-icons";
import { Icon, UilCube } from "@iconscout/react-unicons";

// Définition des interfaces
export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export let routes: RouteItems[] = [];

// Fonction pour vérifier si l'utilisateur a accès à un module spécifique
const hasAccessToModule = (slug: string, moduleAccesses: { slug: string, hasAccess: boolean }[]): boolean => {
  const allAccessModule = moduleAccesses.find(module => module.slug === 'all');
  if (allAccessModule && allAccessModule.hasAccess) {
    return true;
  }
  const module = moduleAccesses.find(module => module.slug === slug);
  return module ? module.hasAccess : false;
};


// Fonction pour filtrer les pages en fonction des accès
const filterRoutes = (routes: RouteItems[], moduleAccesses: { slug: string, hasAccess: boolean }[]): RouteItems[] => {
  return routes.map(routeItem => {
    const filteredPages = routeItem.pages.filter(page => {
      const hasAccess = hasAccessToModule(page.pathName as string, moduleAccesses);
      return hasAccess;
    });
    return {
      ...routeItem,
      pages: filteredPages
    };
  });
};

// Routes pour chaque groupe
const retailerRoutes: RouteItems[] = [
  {
    label: 'Revendeur',
    icon: UilCube,
    pages: [
      { name: 'portail-revendeur', path: "/dashboard/revendeur", pathName: 'portail-revendeur', icon: 'grid', active: true },
      { name: "Commandes et factures", path: "/portail-revendeur/commandes-et-factures", pathName: "commandes-et-factures", icon: "clipboard", active: true },
      { name: "Certifications", path: "/portail-revendeur/certifications", pathName: "certifications", icon: "compass", active: true },
      { name: 'Utilisateurs', path: "/portail-revendeur/utilisateurs", pathName: 'utilisateurs', icon: 'users', active: true },
      { name: 'Vos tickets', path: "/portail-revendeur/vos-tickets", pathName: "vos-tickets", icon: "alert-triangle", active: true },
      { name: 'Téléchargements', path: "/portail-revendeur/téléchargements", pathName: 'téléchargements', icon: 'download', active: true },
      { name: "FAQ", path: "/ipbx/faq", pathName: "faq-revendeur", icon: "help-circle", active: true },
    ]
  },
];

const editorRoutes: RouteItems[] = [
  {
    label: 'Editeur',
    icon: UilCube,
    pages: [
      { name: "Portail éditeur", path: "/dashboard/editeur", pathName: 'portail-editeur', icon: 'grid', active: true },
      { name: "Commandes et factures", path: "/portail-editeur/commandes-et-factures", pathName: "commandes-et-factures", icon: "clipboard", active: true },
      { name: 'Utilisateurs', path: "/portail-editeur/utilisateurs", pathName: 'utilisateurs', icon: 'users', active: true },
      { name: 'Vos tickets', path: "/portail-editeur/vos-tickets", pathName: 'vos-tickets', icon: 'clipboard', active: true },
    ]
  },
];

const ipbxRoutes: RouteItems[] = [
  {
    label: 'IPBX',
    icon: UilCube,
    pages: [
      { name: 'Dashboard', path: "/ipbx/dashboard", pathName: 'portail-ipbx-dashboard', icon: 'grid', active: true },
      { name: 'Utilisateurs', path: "/ipbx/utilisateurs", pathName: 'utilisateurs-dashboard', icon: 'users', active: true },
      { name: 'Info téléphone', path: "/ipbx/téléphones", pathName: 'vos-tickets-dashboard', icon: 'phone', active: true },
      { name: 'Trunks SIP', path: "/ipbx/trunk-sip", pathName: 'gestion-des-droits-utilisateurs-dashboard', icon: 'shuffle', active: true },
      {
        name: 'Gestion des appels',
        icon: 'triangle',
        active: true,
        pages: [
          { name: "Plan d'appels", path: "/ipbx/gestion-des-appels/editor", pathName: "gestion-des-appels-editor", icon: "phone", active: true },
          { name: "Règles entrantes", path: "/ipbx/gestion-des-appels/regles-entrantes", pathName: "gestion-des-appels-regles-entrantes", icon: "user", active: true },
          { name: "Règles sortantes", path: "/ipbx/gestion-des-appels/regles-sortantes", pathName: "gestion-des-appels-regles-sortantes", icon: "user", active: true },
          { name: "Répondeurs numériques", path: "/ipbx/gestion-des-appels/repondeurs-numeriques", pathName: "gestion-des-appels-repondeurs-numeriques", icon: "user", active: true },
          { name: "Groupes d'appels", path: "/ipbx/gestion-des-appels/groupes-d-appels", pathName: "gestion-des-appels-groupes-d-appels", icon: "user", active: true },
          { name: "Files d'attente", path: "/ipbx/gestion-des-appels/files-d-attente", pathName: "gestion-des-appels-files-d-attente", icon: "user", active: true },
          { name: "Musique d'attente", path: "/ipbx/gestion-des-appels/musique-d-attente", pathName: "gestion-des-appels-musique-d-attente", icon: "user", active: true },
        ]
      },
      { name: "Sauvegardes", path: "/ipbx/sauvegardes", pathName: "sauvegarde", icon: 'server', active: true },
      { name: 'Contacts', path: "/ipbx/contacts", pathName: "contacts", icon: "users", active: true },
      {
        name: 'Rapports',
        icon: "file-text",
        active: true,
        pages: [
          { name: "Journaux d'appels", path: "/ipbx/rapport/journaux-appels", pathName: "journaux-appels", icon: "file-text", active: true },
          { name: "Paramètres CDR", path: "/ipbx/rapport/parametres-cdr", pathName: "parametres-cdr", icon: "file-text", active: true },
          { name: "Rapports", path: "/ipbx/rapport/rapports", pathName: "rapports", icon: "file-text", active: true },
          { name: "Enregistrements", path: "/ipbx/rapport/enregistrements", pathName: "enregistrements", icon: "file-text", active: true },
        ]
      },
      {
        name: 'Sécurité',
        icon: 'shield',
        active: true,
        pages: [
          { name: "Anti-piratage", path: "/ipbx/securite/anti-piratage", pathName: "anti-piratage", icon: "lock", active: true },
          { name: "Codes de pays autorisés", path: "/ipbx/securite/codes-de-pays-autorises", pathName: "codes-de-pays-autorises", icon: "flag", active: true },
          { name: "Identifiants racine", path: "/ipbx/securite/identifiants-racine", pathName: "identifiants-racine", icon: "key", active: true },
          { name: "Numéros en liste noire", path: "/ipbx/securite/numeros-en-liste-noire", pathName: "numeros-en-liste-noire", icon: faHourglassHalf, iconSet: "font-awesome", active: true },
          { name: "Liste noire d'adresses IP", path: "/ipbx/securite/liste-noire-IP-adresses", pathName: "liste-noire-IP-adresses", icon: faStopCircle, iconSet: "font-awesome", active: true },
          { name: "SIP sécurisé", path: "/ipbx/securite/SIP-sécurisé", pathName: "SIP-sécurisé", icon: "lock", active: true },
          { name: "Tunnel trunks", path: "/ipbx/securite/tunnel-trunks", pathName: "SIP-sécurisé", icon: "link", active: true },
        ]
      },
      { name: 'FAQ', path: "/ipbx/faq", pathName: "faq", icon: 'help-circle', active: true },
      { name: 'Paramètres', path: "/ipbx/parametres", pathName: 'parametres', icon: 'settings', active: true },
    ]

  },
];

//
// localStorage.setItem('ipbx_access', JSON.stringify([
//     {
//       "ref_ipbx":"ipbx-f6gh6",
//       "phone_config_id":"9",
//       "client_finail_id":"9",
//       "license_id":"9"
//     },
// ]));

// Retrieve ipbx_access from localStorage
const ipbxAccessJson = localStorage.getItem('ipbx_access');
console.log('ipbx_access data from localStorage:', ipbxAccessJson);  // Debug log

// Parse the retrieved JSON and access the array directly
let ipbxAccesses = [];
try {
  ipbxAccesses = ipbxAccessJson ? JSON.parse(ipbxAccessJson) : []; // Directly parse the array
  if (!Array.isArray(ipbxAccesses)) {
    throw new Error('ipbx_access is not an array');
  }
} catch (error) {
  console.error('Error parsing ipbx_access from localStorage:', error);
  ipbxAccesses = [];
}

// Generate dynamic IPBX routes based on ipbx_access
const dynamicIpbxRoutes = ipbxAccesses.map((access: { ref_ipbx: string; phone_config_id: string; client_finail_id: string; license_id: string; }) => ({
  label: `${access.ref_ipbx}`,  
  icon: UilCube,
  pages: [
    { name: 'Dashboard', path: "/ipbx/dashboard", pathName: 'portail-ipbx-dashboard', icon: 'grid', active: true },
    { name: 'Utilisateurs', path: "/ipbx/utilisateurs", pathName: 'utilisateurs-dashboard', icon: 'users', active: true },
    { name: 'Info téléphone', path: "/ipbx/téléphones", pathName: 'vos-tickets-dashboard', icon: 'phone', active: true },
    { name: 'Trunks SIP', path: "/ipbx/trunk-sip", pathName: 'gestion-des-droits-utilisateurs-dashboard', icon: 'shuffle', active: true },
    {
      name: 'Gestion des appels',
      icon: 'triangle',
      active: true,
      pages: [
        { name: "Plan d'appels", path: "/ipbx/gestion-des-appels/editor", pathName: "gestion-des-appels-editor", icon: "phone", active: true },
        { name: "Règles entrantes", path: "/ipbx/gestion-des-appels/regles-entrantes", pathName: "gestion-des-appels-regles-entrantes", icon: "arrow-up", active: true },
        { name: "Règles sortantes", path: "/ipbx/gestion-des-appels/regles-sortantes", pathName: "gestion-des-appels-regles-sortantes", icon: "arrow-down", active: true },
        { name: "Répondeurs numériques", path: "/ipbx/gestion-des-appels/repondeurs-numeriques", pathName: "gestion-des-appels-repondeurs-numeriques", icon: "voicemail", active: true },
        { name: "Groupes d'appels", path: "/ipbx/gestion-des-appels/groupes-d-appels", pathName: "gestion-des-appels-groupes-d-appels", icon: "users", active: true },
        { name: "Files d'attente", path: "/ipbx/gestion-des-appels/files-d-attente", pathName: "gestion-des-appels-files-d-attente", icon: "clock", active: true },
        { name: "Musique d'attente", path: "/ipbx/gestion-des-appels/musique-d-attente", pathName: "gestion-des-appels-musique-d-attente", icon: "music", active: true },
        
      ]
    },
    { name: "Sauvegardes", path: "/ipbx/sauvegardes", pathName: "sauvegarde", icon: 'server', active: true },
    { name: 'Contacts', path: "/ipbx/contacts", pathName: "contacts", icon: "users", active: true },
    {
      name: 'Rapports',
      icon: "file-text",
      active: true,
      pages: [
        { name: "Journaux d'appels", path: "/ipbx/rapport/journaux-appels", pathName: "journaux-appels", icon: "file-text", active: true },
        { name: "Paramètres CDR", path: "/ipbx/rapport/parametres-cdr", pathName: "parametres-cdr", icon: "file-text", active: true },
        { name: "Rapports", path: "/ipbx/rapport/rapports", pathName: "rapports", icon: "file-text", active: true },
        { name: "Enregistrements", path: "/ipbx/rapport/enregistrements", pathName: "enregistrements", icon: "file-text", active: true },
      ]
    },
    {
      name: 'Sécurité',
      icon: 'shield',
      active: true,
      pages: [
        { name: "Anti-piratage", path: "/ipbx/securite/anti-piratage", pathName: "anti-piratage", icon: "lock", active: true },
        { name: "Codes de pays autorisés", path: "/ipbx/securite/codes-de-pays-autorises", pathName: "codes-de-pays-autorises", icon: "flag", active: true },
        { name: "Identifiants racine", path: "/ipbx/securite/identifiants-racine", pathName: "identifiants-racine", icon: "key", active: true },
        { name: "Numéros en liste noire", path: "/ipbx/securite/numeros-en-liste-noire", pathName: "numeros-en-liste-noire", icon: faHourglassHalf, iconSet: "font-awesome", active: true },
        { name: "Liste noire d'adresses IP", path: "/ipbx/securite/liste-noire-IP-adresses", pathName: "liste-noire-IP-adresses", icon: faStopCircle, iconSet: "font-awesome", active: true },
        { name: "SIP sécurisé", path: "/ipbx/securite/SIP-sécurisé", pathName: "SIP-sécurisé", icon: "lock", active: true },
        { name: "Tunnel trunks", path: "/ipbx/securite/tunnel-trunks", pathName: "SIP-sécurisé", icon: "link", active: true },
      ]
    },
    { name: 'FAQ', path: "/ipbx/faq", pathName: "faq", icon: 'help-circle', active: true },
    { name: 'Paramètres', path: "/ipbx/parametres", pathName: 'parametres', icon: 'settings', active: true },
  ]

}));

const dynamicIpbxRoutes2 = ipbxAccesses.map((access: { ref_ipbx: string; phone_config_id: string; client_finail_id: string; license_id: string; }) => ({
  label: `${access.ref_ipbx}`,  // Label for the menu item
  icon: UilCube,
  pages: [
    { name: 'Dashboard', path: `/${access.ref_ipbx}/dashboard`, pathName: `${access.ref_ipbx}-dashboard`, icon: 'grid', active: true },
    { name: 'Utilisateurs', path: `/${access.ref_ipbx}/utilisateurs`, pathName: `${access.ref_ipbx}-utilisateurs`, icon: 'users', active: true },
    { name: 'Info téléphone', path: `/${access.ref_ipbx}/téléphones`, pathName: `${access.ref_ipbx}-téléphones`, icon: 'phone', active: true },
    { name: 'Trunks SIP', path: `/${access.ref_ipbx}/trunk-sip`, pathName: `${access.ref_ipbx}-trunk-sip`, icon: 'shuffle', active: true },
    {
      name: 'Gestion des appels',
      icon: 'triangle',
      active: true,
      pages: [
        { name: "Plan d'appels", path: `/${access.ref_ipbx}/gestion-des-appels/editor`, pathName: `${access.ref_ipbx}-gestion-des-appels-editor`, icon: "phone", active: true },
        { name: "Règles entrantes", path: `/${access.ref_ipbx}/gestion-des-appels/regles-entrantes`, pathName: `${access.ref_ipbx}-gestion-des-appels-regles-entrantes`, icon: "user", active: true },
        { name: "Règles sortantes", path: `/${access.ref_ipbx}/gestion-des-appels/regles-sortantes`, pathName: `${access.ref_ipbx}-gestion-des-appels-regles-sortantes`, icon: "user", active: true },
      ]
    },
    { name: 'FAQ', path: `/${access.ref_ipbx}/faq`, pathName: `${access.ref_ipbx}-faq`, icon: 'help-circle', active: true },
  ]
}));

// Log the dynamic routes to verify
// console.log('Dynamic IPBX Routes:', dynamicIpbxRoutes);


const defaultRoutes: RouteItems[] = [
  {
    label: 'default',
    icon: UilCube,
    pages: [
      { name: 'FAQ', path: "/ipbx/faq", pathName: "faq", icon: 'help-circle', active: true },
    ]
  },
];
function filterValidRoutes(routes: any[]): RouteItems[] {
  return routes;
}


// Fonction pour obtenir les routes filtrées en fonction du rôle de l'utilisateur
export const getRoutes = (userGroup: string, moduleAccesses: { slug: string, hasAccess: boolean }[]): RouteItems[] => {
  // console.log('Routes retournées:', routes);

  switch (userGroup) {
    case 'retailer':
      return [
        ...filterRoutes(retailerRoutes, moduleAccesses),
        ...filterValidRoutes(dynamicIpbxRoutes)
      ];
    case 'editor':
      return [
        ...filterRoutes(editorRoutes, moduleAccesses),
        ...filterValidRoutes(dynamicIpbxRoutes)
      ];
    // case 'ipbx':
    //   return filterRoutes(dynamicIpbxRoutes, moduleAccesses);
    case 'all':
      return [
        ...filterRoutes(retailerRoutes, moduleAccesses),
        ...filterRoutes(editorRoutes, moduleAccesses),
        ...filterValidRoutes(dynamicIpbxRoutes)
      ];
    default:
      return filterRoutes(defaultRoutes, moduleAccesses);
  }



};

// Initialisation des routes en fonction des données de localStorage
const userGroup = localStorage.getItem('userGroup') || 'default';
const moduleAccessesJson = localStorage.getItem('moduleAccesses');
const moduleAccesses = moduleAccessesJson ? JSON.parse(moduleAccessesJson) : [];

routes = getRoutes(userGroup, moduleAccesses);
