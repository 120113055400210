import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faPencil } from "@fortawesome/free-solid-svg-icons";
import useFinalClientByID from "hooks/api/ipbx/useFinalClientByID";
import IpbxPrintingDimensionsCard from "components/cards/ipbx/IpbxPrintingDimensionsCard";
import { stats } from "data/iper-x/ipbx/ipbxDetailsInfo";
import classNames from "classnames";
import AdClicksChart from "components/charts/e-charts/AdClicksChart";
import ContactsCreatedChart from "components/charts/e-charts/ContactsCreatedChart";
import InfoList from "components/modules/ipbx/info-list/InfoList";
import useServerByID from "hooks/api/ipbx/useServerByID";

const DashboardIpbx = () => {
  const [showModal, setShowModal] = useState(false);
  const [clientName, setClientName] = useState("");


  // Get the license ID from localStorage
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;


  const { finalClientData } = useFinalClientByID(clientFinalId);
  const serverId = finalClientData?.idInstance;

  const { serverByIDData } = useServerByID(serverId as number);
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    email: "",
    address: "",
    siret: "",
  });

  useEffect(() => {
    if (finalClientData) {
      setFormData({
        name: finalClientData.name,
        position: finalClientData.position,
        email: finalClientData.email,
        address: finalClientData.address,
        siret: finalClientData.siret
      });
      setClientName(finalClientData.name);
    }
  }, [finalClientData]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleEdit = async () => {
    try {
      if (!finalClientData) {
        console.error("finalClientData is null");
        return;
      }

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/clientfinal', {
        method: 'PUT',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          id: finalClientData.id,
          idRevendeur: finalClientData.idRevendeur,
          createdBy: finalClientData.createdBy,
          idInstance: finalClientData.idInstance,
          ...formData
        }),
      });

      if (response.ok) {
        window.location.reload(); // Refresh page to see updated data
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du client final", error);
    }
  };

  return (
    <>
      <Col md={9} xs="auto">
        <h3 className="fw-bolder mb-2">{clientName}
          <FontAwesomeIcon
            icon={faPencil}
            className="fs-10 text-500 ms-3"
            onClick={handleOpenModal}
            style={{ cursor: "pointer" }}
          />
        </h3>
        {finalClientData && (
  <div className="d-flex align-items-center mb-3">
    <span className="me-3"><strong>Poste:</strong> {finalClientData.position || "non défini"}</span>
    <span className="mx-2">|</span>
    <span className="me-3"><strong>Email:</strong> {finalClientData.email || "non défini"}</span>
    <span className="mx-2">|</span>
    <span className="me-3"><strong>Adresse:</strong> {finalClientData.address || "non défini"}</span>
    <span className="mx-2">|</span>
    <span><strong>Siret:</strong> {finalClientData.siret || "non défini"}</span>
  </div>
)}



      </Col>

      <div className="mb-9" data-bs-spy="scroll">
        <div className="mt-3 mx-lg-n4">
          <IpbxPrintingDimensionsCard stats={stats} className="mb-9" />

          <div className="mb-9">
            <Row className="g-3 mt-n7">
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Statut système</h3>
                    <div className="mt-5">
                      <AdClicksChart style={{ height: 270, width: "100%" }} />
                    </div>
                    <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between">
                      <Col key={1} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            " ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Usage du disque</h4>
                            <p className="fw-bold mb-1">
                              15% utilisé (49.8 GB restant)
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col key={2} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Mémoire</h4>
                            <p className="fw-bold mb-1">
                              50% utilisé, 979.7 MB restant
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col key={3} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5 mx-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">CPU</h4>
                            <p className="fw-bold mb-1">2/100</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Trafic aujourd'hui</h3>
                    <div className="mt-5 mb-5">
                      <ContactsCreatedChart
                        style={{ height: 270, width: "100%" }}
                      />
                    </div>
                    <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between">
                      <Col key={1} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            " ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Appel entrant</h4>
                            <p className="fw-bold mb-1">1</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={2} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Appel sortant</h4>
                            <p className="fw-bold mb-1">
                              9 inscrits/ 21 non inscrits{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col key={3} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Interne</h4>
                            <p className="fw-bold mb-1">12</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={4} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Transite</h4>
                            <p className="fw-bold mb-1">32</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="mb-9">
            <Row className="g-3 mt-n7">
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Statut PBX</h3>
                    <InfoList data="status" />
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Informations</h3>
                    <InfoList data="info" />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* Modal for editing client information */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier les informations du client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nom de la société</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}

              />
            </Form.Group>
            <Form.Group controlId="formPosition">
              <Form.Label>Poste</Form.Label>
              <Form.Control
                as="select"
                name="position"
                className="form-select"
                value={formData.position}
                onChange={handleChange}
              >
                <option value="">Sélectionnez un poste</option>
                <option value="pdg">PDG</option>
                <option value="directeur_commercial">Directeur commercial</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}

              />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Adresse</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Format: N° rue ville code postal pays"
                value={formData.address}
                onChange={handleChange}

              />
            </Form.Group>
            <Form.Group controlId="siret">
              <Form.Label>Siret</Form.Label>
              <Form.Control
                type="text"
                name="siret"
                value={formData.siret}
                onChange={handleChange}

              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Enregistrer les modifications
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardIpbx;
