import PhoenixDocCard from 'components/base/PhoenixDocCard';
import DocPageHeader from 'components/docs/DocPageHeader';
import DocPagesLayout from 'layouts/old/DocPagesLayout';
import { Col, Row } from "react-bootstrap";
import React from "react";
import PhoenixLiveEditor from "../../../../components/docs/PhoenixLiveEditor";

const userInfo = `

function FormExample() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Scannez ce code QR depuis l'application Iper-x Android ou IOS pour la provisionner pour cette extension</label>
        <img src="https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcSh-wrQu254qFaRcoYktJ5QmUhmuUedlbeMaQeaozAVD4lh4ICsGdBNubZ8UlMvWjKC" alt="QR Code" style={{ height: "180px", width: "180px" }} />
      </div>

      <Row className="mb-3 gx-3">
        <Form.Group as={Col} md="4" controlId="tooltipExtension" className="position-relative">
          <Form.Label>Extension</Form.Label>
          <Form.Control type="text" placeholder="Extension" defaultValue="12" disabled />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="tooltipFirstName" className="position-relative">
          <Form.Label>Prénom</Form.Label>
          <Form.Control required type="text" placeholder="Prénom" defaultValue="" />
          <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="tooltipLastName" className="position-relative">
          <Form.Label>Nom</Form.Label>
          <Form.Control required type="text" placeholder="Nom" defaultValue="" />
          <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3 gx-3">
        <Form.Group as={Col} md="4" controlId="tooltipEmail" className="position-relative">
          <Form.Label>Adresse email</Form.Label>
          <Form.Control type="email" placeholder="Adresse email" defaultValue="valeriehuin@gmail.com" required />
          <Form.Control.Feedback type="invalid" tooltip>Veuillez fournir une adresse email valide.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="tooltipMobile" className="position-relative">
          <Form.Label>Numéro mobile</Form.Label>
          <Form.Control type="text" placeholder="Numéro mobile" defaultValue="0556246218" required />
          <Form.Control.Feedback type="invalid" tooltip>Veuillez fournir un numéro de mobile valide.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="tooltipOutboundCallerId" className="position-relative">
          <Form.Label>Numéro présenté</Form.Label>
          <Form.Control type="text" placeholder="Numéro présenté" defaultValue="" required />
          <Form.Control.Feedback type="invalid" tooltip>Veuillez fournir un numéro présenté valide.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" className="position-relative">
        <Form.Check required label="Accepter les termes et conditions" feedback="Vous devez accepter avant de soumettre." />
      </Form.Group>
      <Button type="submit">Soumettre le formulaire</Button>

      <div className="panel panel-default" style={{ height: "100%" }}>
        <div className="panel-body">
          <p className="m-b ng-scope" translate="EXTENSIONS.EDITOR.GENERAL.WEB_AUTH_DESCRIPTION">Vous pouvez visualiser la présence de vos collègues, rediriger ou transférer des appels en un clic de souris, voir l'historique de vos appels et organiser des conférences téléphoniques en utilisant le client web/la Desktop App.</p>
          <a href="https://frenchburgers.onIper-x.fr/webclient" target="_blank" className="dashed_link m-r-xs white-space-break"><strong className="ng-binding">https://frenchburgers.onIper-x.fr/webclient</strong></a>
          <button className="btn btn-sm btn-default" type="button"><i className="fa fa-clipboard"></i></button>
        </div>
      </div>

      <div className="form-group">
        <span>Activer le client web/la Desktop App</span>
        <input type="checkbox" />
      </div>

      <div className="form-group m-b">
        <label aria-hidden="false">Mot de passe - L'utilisateur est un numéro d'extension</label>
        <input type="password" className="form-control m-b ng-pristine ng-valid ng-empty ng-valid-maxlength conceal ng-touched" placeholder="Mot de passe" maxLength="50" aria-invalid="false" style={{ width: '100%' }} />
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <p className="m-b ng-scope" translate="EXTENSIONS.EDITOR.GENERAL.DIRECT_DID_INFO">Assignez optionnellement un SDA afin de recevoir des appels directement sur l'extension</p>
          <div className="m-b-sm">
            <button type="button" className="btn btn-sm btn-success ng-scope">Ajouter un SDA</button>
          </div>
        </div>
      </div>
    </Form>
  );
}

`;

const voicemailInfo = `
function VoicemailForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Check type="checkbox" label="Activer la messagerie vocale" />
      </Form.Group>
      
      <Form.Group>
        <Form.Label>Langue de la messagerie vocale</Form.Label>
        <Form.Control as="select">
          <option value="fr">French</option>
          <option value="en">English</option>
          <option value="es">Spanish</option>
          {/* Ajoutez d'autres options de langue ici */}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>French Prompts Set</Form.Label>
        <Form.Control type="text" defaultValue="French Prompts Set" />
      </Form.Group>

      <Form.Group>
        <Form.Label>Code PIN</Form.Label>
        <Form.Control type="password" defaultValue="3540" />
      </Form.Group>

      <Form.Group>
        <Form.Label>Lire date/heure</Form.Label>
        <Form.Control as="select">
          <option value="yes">Oui</option>
          <option value="no">Non</option>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Options email</Form.Label>
        <Form.Control as="select">
          <option value="notification">Envoyer seulement un email de notification</option>
          {/* Ajoutez d'autres options d'email ici */}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Check type="checkbox" label="Désactiver l'authentification PIN pour la messagerie vocale" />
      </Form.Group>

      <Form.Group>
        <Form.Check type="checkbox" label="Lire l'ID d'appelant" />
      </Form.Group>

      <Form.Group>
        <Form.Label>Gérer les messages d'accueil de messagerie vocale</Form.Label>
        <Form.Control type="file" accept="audio/wav" />
        <Form.Text className="text-muted">Format WAV (PCM, 8 kHz, 16 bit, Mono, taille max : 100MB)</Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Label>Choisissez le message de salutation par défaut (pour tous les statuts). Pour configurer des messages spécifiques à chaque statut, rendez-vous dans les Règles de transfert</Form.Label>
      </Form.Group>

      <Button type="submit">Soumettre</Button>
    </Form>
  );
}

`;

const transferRulesForm = `

function TransferRulesForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Statuts</Form.Label>
        <div className="status-item">
          <Form.Check type="radio" label="Disponible" id="status-available" />
          <div className="transfer-options">
            <span>Si dans ce statut, transférer tous les appels comme suit :</span>
            <div>
              <Form.Label>Appels internes</Form.Label>
              <div>
                <span>Si je ne réponds pas aux appels au bout de :</span>
                <Form.Control type="number" defaultValue="80" />
                <span>secondes. Transférer les appels internes à :</span>
                <Form.Control as="select">
                  <option value="voicemail">Transférer à la messagerie vocale</option>
                  {/* Ajoutez d'autres options de transfert ici */}
                </Form.Control>
              </div>
            </div>
            <div>
              <Form.Label>Appels externes</Form.Label>
              <div>
                <span>Après timeout transférer les appels externes à :</span>
                <Form.Control as="select">
                  <option value="voicemail">Transférer à la messagerie vocale</option>
                  {/* Ajoutez d'autres options de transfert ici */}
                </Form.Control>
              </div>
            </div>
          </div>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Options</Form.Label>
        <div className="options">
          <Form.Check type="checkbox" label="Faire sonner mon mobile simultanément" />
          <Form.Check type="checkbox" label="Accepter plus d'un appel" />
          <Form.Check type="checkbox" label="Se déconnecter des files" />
          <Form.Check type="checkbox" label="Accepter les appels de groupes d'appel" />
          <Form.Check type="checkbox" label="Accepter les notifications Push" />
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Message de salutation</Form.Label>
        <div className="greeting">
          <span>Lorsque vous sélectionnez ce statut, utilisez ce message d'accueil de messagerie vocale personnalisé :</span>
          <Form.Control type="file" accept=".wav" />
          <Form.Text className="text-muted">Format WAV (PCM, 8 kHz, 16 bit, Mono, taille max : 100MB)</Form.Text>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Exceptions</Form.Label>
        <div className="exceptions">
          <span>Appliquer des règles différentes pour les appelants suivant</span>
          {/* Ajoutez ici les règles d'exception */}
        </div>
      </Form.Group>
      <Button type="submit">Soumettre</Button>
    </Form>
  );
}


`;

const phoneConfigurationForm = `
function PhoneConfiguration() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>

    {/* Choix du téléphone */}
      <h3>Choix du téléphone</h3>
      <Row className="mb-3 gx-3 ">
        <Form.Group as={Col} md="6" controlId="formPhoneModel" className="position-relative">
          <Form.Label>Modèle de téléphone</Form.Label>
          <Form.Control as="select">
            <option value="phoneModel1">Modèle 1</option>
            <option value="phoneModel2">Modèle 2</option>
            <option value="phoneModel3">Modèle 3</option>
          </Form.Control>
        </Form.Group>
      </Row>


      {/* Informations Iper-x App */}
      <h4>Iper-x App</h4>
      <Row className="mb-3 gx-3">
        <Form.Group as={Col} md="6" controlId="formPhoneID" className="position-relative">
          <Form.Label>ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="ID"
            defaultValue="uZNPuS32Tq"
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formPhonePassword" className="position-relative">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Mot de passe"
            defaultValue="••••••••••"
            disabled
          />
        </Form.Group>
      </Row>

      {/* Informations réseau */}
      <h3>Réseau</h3>
      <Row className="mb-3 gx-3">
        <Form.Group as={Col} md="6" controlId="formNetworkInterface" className="position-relative">
          <Form.Label>Interface réseau</Form.Label>
          <Form.Control
            type="text"
            placeholder="Interface réseau"
            defaultValue="demo.Iper-x.fr"
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formTransportSIP" className="position-relative">
          <Form.Label>Transport SIP</Form.Label>
          <Form.Control
            type="text"
            placeholder="Transport SIP"
            defaultValue="UDP"
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formModeRTP" className="position-relative">
          <Form.Label>Mode RTP</Form.Label>
          <Form.Control
            type="text"
            placeholder="Mode RTP"
            defaultValue="Normal"
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formModeDTMF" className="position-relative">
          <Form.Label>Mode DTMF</Form.Label>
          <Form.Control
            type="text"
            placeholder="Mode DTMF"
            defaultValue="RFC2833"
            disabled
          />
        </Form.Group>
      </Row>

      {/* Options */}
      <h3>Options</h3>
      <Row className="mb-3 gx-3">
        <Form.Group as={Col} md="6" controlId="formPushNotifications" className="position-relative">
          <Form.Check
            type="checkbox"
            label="Activer les notifications PUSH"
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formReprovisionAtStartup" className="position-relative">
          <Form.Check
            type="checkbox"
            label="Re-provisionner le téléphone au démarrage"
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formUseIper-xTunnel" className="position-relative">
          <Form.Check
            type="checkbox"
            label="Utiliser le tunnel Iper-x pour les connexions distantes (applications Iper-x uniquement)"
            disabled
          />
        </Form.Group>
      </Row>

      {/* Préférences */}
      <h3>Préférences</h3>
      <Row className="mb-3 gx-3">
        {/* Contrôle des appels */}
        <Form.Group as={Col} md="6" controlId="formCallControl" className="position-relative">
          <Form.Label>Contrôle des appels</Form.Label>
          <Form.Control
            as="select"
            defaultValue="Softphone"
            disabled
          >
            <option>Softphone</option>
            {/* Autres options */}
          </Form.Control>
        </Form.Group>
        {/* Autres préférences */}
      </Row>
      
 {/* Gestion des Accès */}
      <h3>Gestion des Accès</h3>
      <Row className="mb-3 gx-3">
        <Form.Group as={Col} md="6" controlId="formBlockWebAccess" className="position-relative">
          <Form.Check type="checkbox" label="Bloquer l'accès aux applications / client web Iper-x" />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formHideTransferRules" className="position-relative">
          <Form.Check type="checkbox" label="Cacher les règles de transfert" />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formShowCallRecordings" className="position-relative">
          <Form.Check type="checkbox" label="Afficher les enregistrements d'appel" />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formAllowDeleteRecordings" className="position-relative">
          <Form.Check type="checkbox" label="Autoriser la suppression des enregistrements" />
        </Form.Group>
      </Row>
      
      {/* Bouton de soumission */}
      <Button type="submit">Enregistrer</Button>
    </Form>
  );
}

`;

const blfSettingsForm = `
function BLFSettings() {
  const options = [
    "Non assignée",
    "Touche de ligne",
    "BLF",
    "Raccourci d'appel",
    "Raccourci personnalisé",
    "Parking d'appel",
    "Connexion/Déconnexion files",
    "Changer de statut"
  ];

  return (
    <div>
      <h4>Assigner les boutons BLF aux présences des autres extensions, raccourcis, parkings partagés ou autre fonctions.</h4>
      <p>Ces paramètres sont appliqués à chacun des téléphones et applications Iper-x.</p>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Assigner</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((_, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <select className="form-select">
                  {options.map((option, i) => (
                    <option key={i} value={option}>{option}</option>
                  ))}
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="btn btn-primary">Ajouter plus de BLFs</button>
    </div>
  );
}

`;


const optionsSettingsForm = `

function RightsRestrictions() {
  const categories = [
    {
      name: "Général",
      options: [
        "Désactiver l'extension",
        "Désactiver les appels externes",
        "Activer la protection par code PIN pour",
        "Masquer cette extension de l’annuaire d’entreprise Iper-x et des applications Iper-x"
      ]
    },
    {
      name: "Connexions",
      options: [
        "Bloquer les téléphones distants utilisant stun (non sécurisé !)",
        "Bloquer les connexions distantes par tunnel (les connexions aux applications Iper-x avec le tunnel activé et le SBC seront bloquées)"
      ]
    },
    {
      name: "Enregistrement d'appels",
      options: [
        "Enregistrement désactivé",
        "Enregistrer tous les appels",
        "Enregistrer les appels externes seulement",
        "Permettre aux utilisateurs de commencer et d'arrêter l'enregistrement (en appuyant sur le bouton enregistrer sur les clients) (Disponible en édition Entreprise seulement)"
      ]
    },
    {
      name: "Options",
      options: [
        "Activer le Hotdesking (Disponible en Professionnel et Entreprise)",
        "Filtrer les appels en demandant le nom de l'appelant par SVI",
        "Envoyer une notification email sur appel manqué",
        "ID SIP (doit être unique pour chaque extension)",
        "Dépannage",
        "Le PBX délivre l'audio",
        "Support des Re-Invites",
        "Support de l'entête 'Replaces'",
        "Activer les intégrations (legacy)"
      ]
    }
  ];

  return (
    <div>
      <h4>Droits et Restrictions</h4>
      <p>Veuillez sélectionner les options de droits et restrictions pour cette extension :</p>
      {categories.map((category, index) => (
        <div key={index}>
          <h5>{category.name}</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Option</th>
                <th>Statut</th>
              </tr>
            </thead>
            <tbody>
              {category.options.map((option, idx) => (
                <tr key={idx}>
                  <td>{option}</td>
                  <td>
                    <select className="form-select">
                      <option value="enable">Activer</option>
                      <option value="disable">Désactiver</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <p>Veuillez sélectionner le package d'intégration souhaité et redémarrer l'application Iper-x :</p>
      <select className="form-select">
        <option value="package1">Package 1</option>
        <option value="package2">Package 2</option>
        {/* Add more integration package options here */}
      </select>
      <button className="btn btn-primary mt-3">Redémarrer l'application Iper-x</button>
    </div>
  );
}


`;


const rightSettingsForm = `

function GroupMembersManagement() {
  const groupPermissions = [
    {
      name: "Utilisateur",
      permissions: [
        "Peut voir les membres du groupe",
        "Peut voir les appels du groupe",
        "Diffuse sa présence au groupe",
        "Montre ses appels au groupe",
        "Effectue des opérations (diversion, transfert, interception d'appel)",
        "Peut intervenir, écouter, ou chuchoter (Disponible en Professionnel et Entreprise)",
        "Peut utiliser l'interphone",
        "Peut parquer les appels",
        "Peut manipuler les appels en répondeur numérique",
        "Peut gérer l'annuaire d'entreprise",
        "Effectue des opérations de réceptionniste, telles que la configuration du statut, le paramétrage de rappels (réveils téléphoniques), l'assignation ou la suppression d'une extension (Check-in/out)",
        "Peut voir les enregistrements et rapports de groupes",
        "Autorise l'accès à la console d'administration de Iper-x"
      ]
    },
    {
      name: "Gestion des extensions",
      permissions: [
        "Peut gérer seulement son extension",
        "Peut gérer seulement les extensions de son groupe",
        "Peut gérer toutes les extensions"
      ]
    },
    {
      name: "Gestion du système",
      permissions: [
        "Administration des trunks SIP (Peut administrer les trunks SIP, les règles entrantes et sortantes)",
        "Administration système (Peut administrer le standard téléphonique)",
        "Administration du standard téléphonique (Peut administrer les règles entrantes, les répondeurs numériques, les groupes d'appels, les files d'attente, les contacts et la musique d'attente du système)",
        "Surveiller (A accès aux rapports, journaux de chats, journaux d'appels & enregistrements)",
        "Peut télécharger des enregistrements"
      ]
    }
  ];

  return (
    <div>
      <h4>Membre de groupes</h4>
      <p>Veuillez sélectionner les autorisations pour chaque membre de groupe :</p>
      {groupPermissions.map((group, index) => (
        <div key={index}>
          <h5>{group.name}</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Permission</th>
                <th>Statut</th>
              </tr>
            </thead>
            <tbody>
              {group.permissions.map((permission, idx) => (
                <tr key={idx}>
                  <td>{permission}</td>
                  <td>
                    <select className="form-select">
                      <option value="enable">Activer</option>
                      <option value="disable">Désactiver</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <button className="btn btn-primary mt-3">Sauvegarder les autorisations</button>
    </div>
  );
}

`;


const AddExtensionDeviceForm = () => {
  return (
    <div>
      <DocPageHeader
        title="Ajouter un telephone"
      />

      <DocPagesLayout>



        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Informations utilisateur">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={userInfo} />
        </PhoenixDocCard>
{/* 
        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Messagerie vocale">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={voicemailInfo} />
        </PhoenixDocCard>

        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Règles de transfert">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={transferRulesForm} />
        </PhoenixDocCard> */}


        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Configuration téléphone">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={phoneConfigurationForm} />
        </PhoenixDocCard>

        {/* <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="BLF">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={blfSettingsForm} />
        </PhoenixDocCard>

        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Options">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={ optionsSettingsForm } />
        </PhoenixDocCard>


        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Droits">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={rightSettingsForm} />
        </PhoenixDocCard> */}



      </DocPagesLayout>
    </div>
  );
};

export default AddExtensionDeviceForm;
