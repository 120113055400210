import { useState, useEffect } from 'react';

export interface Assignee {
  id: number;
  name: string;
  avatar?: string;
}

export interface Ticket {
  id: number;
  author: string;
  subject: string;
  type: string;
  severity: string;
  priority: string;
  company: string;
  assignee: string;
  creationDate: string;
  status: { label: string; type?: string };
  assigness: Assignee[];
  progress?: { min: number; max: number };
  task: number;
  bg: string;
  budget: number;
}

const useTicketsData = () => {
  const [data, setData] = useState<Ticket[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

    const fetchTickets = async () => {
      try {
        const response = await fetch(
          'https://erp.ipercom.io/api/index.php/tickets?sortfield=t.rowid&sortorder=ASC&limit=100',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: DOLAPIKEY || '',
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Tickets API Response:', result);

        if (!Array.isArray(result)) {
          throw new Error('Invalid data format from API');
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error fetching tickets:', error.message);
          throw error;
        } else {
          console.error('Unexpected error:', error);
          throw new Error('Unexpected error fetching tickets');
        }
      }
    };

    const fetchUserById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/users/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const user = await response.json();
        console.log(`User ${id} API Response:`, user);
        return { id: user.rowid, name: `${user.firstname} ${user.lastname}`, avatar: user.avatar };
      } catch (error) {
        console.error(`Error fetching user ${id}:`, error);
        throw error;
      }
    };

    const fetchThirdPartyById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/thirdparties/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const thirdParty = await response.json();
        console.log(`Third Party ${id} API Response:`, thirdParty);
        return { id: thirdParty.id, name: thirdParty.name };
      } catch (error) {
        console.error(`Error fetching third party ${id}:`, error);
        throw error;
      }
    };

    const fetchData = async () => {
      try {
        const tickets = await fetchTickets();
        const usersMap: { [key: number]: Assignee } = {};
        const thirdPartiesMap: { [key: number]: string } = {};

        for (const ticket of tickets) {
          if (ticket.fk_user_create && !usersMap[ticket.fk_user_create]) {
            usersMap[ticket.fk_user_create] = await fetchUserById(ticket.fk_user_create);
          }
          if (ticket.fk_user_assign && !usersMap[ticket.fk_user_assign]) {
            usersMap[ticket.fk_user_assign] = await fetchUserById(ticket.fk_user_assign);
          }
          if (ticket.socid && !thirdPartiesMap[ticket.socid]) {
            thirdPartiesMap[ticket.socid] = (await fetchThirdPartyById(ticket.socid)).name;
          }
        }

        console.log('User Map:', usersMap);
        console.log('Third Parties Map:', thirdPartiesMap);

        const ticketsWithDetails: Ticket[] = tickets.map((ticket: any) => {
          console.log(`Processing ticket ${ticket.id} with user ${ticket.fk_user_create}`);
          return {
            id: Number(ticket.id),
            author: usersMap[ticket.fk_user_create]?.name || 'Unknown Author',
            subject: ticket.subject || 'Unnamed Ticket',
            type: ticket.type_label || 'Unknown Type', // Assuming type_label is provided in ticket data
            severity: ticket.severity_label || 'Unknown Severity', // Assuming severity_label is provided in ticket data
            priority: ticket.priority_label || 'Unknown priority', // Assuming severity_label is provided in ticket data
            company: thirdPartiesMap[ticket.socid] || 'Unknown Company',
            assignee: usersMap[ticket.fk_user_assign]?.name || 'Unassigned',
            creationDate: new Date(ticket.date_creation * 1000).toISOString().split('T')[0],
            status: {
              label: ticket.status_label || (ticket.status === '2' ? 'In Progress' : 'Completed'),
              type: ticket.status === '2' ? 'warning' : 'success'
            },
            assigness: ticket.assigness ? ticket.assigness.map((assigne: any) => ({
              id: assigne.id,
              name: usersMap[assigne.id]?.name || 'Unknown',
              avatar: usersMap[assigne.id]?.avatar
            })) : (ticket.fk_user_assign ? [{ id: ticket.fk_user_assign, name: usersMap[ticket.fk_user_assign]?.name || 'Unknown' }] : []),
            progress: {
              min: 0,
              max: Number(ticket.progress) || 100
            },
            task: 123,
            bg: 'bg55',
            budget: 1000
          };
        });

        setData(ticketsWithDetails);
      } catch (error) {
        setError('Unable to fetch data. Please try again later.');
      }
    };

    fetchData();
  }, []);

  return { data, error };
};

export default useTicketsData;
