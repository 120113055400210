import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { InfoItem, infoList, statusList } from "data/iper-x/ipbx/infoListData";
import classNames from "classnames";
import InfoListItem from "./InfoListItem";

interface InfoListProps {
  data: string; 
}

const InfoList: React.FC<InfoListProps> = ({ data }) => {
  const [, setSelectedItem] = useState<InfoItem | null>(null);


  const getData = () => {
    switch (data) {
      case "info":
        return infoList;
      case "status":
        return statusList;

      default:
        return infoList;
    }
  };

  return (
    <Card.Body className="py-0 scrollbar to-do-list-body mt-5">
      {getData().map((todo, index) => (
        <InfoListItem
          key={todo.task}
          todo={todo}
          className={classNames({
            "border-top": index === 0
          })}
          labelClassName="text-900"
          fullLayoutBreakpoints={["md", "xxl"]}
          halfLayoutBreakpoints={["xl"]}
          onClick={setSelectedItem}
        />
      ))}
    </Card.Body>
  );
};

export default InfoList;
