import { useState, useCallback, useEffect } from "react";

export interface FinalClientData {
  id: number;
  id_revendeur: number;
  created_by: number;
  id_instance: number;
  name: string;
  position: string;
  email: string;
  address: string;
}

// Fallback data au cas où il y a une erreur de récupération des données
const fallBackClientData: FinalClientData[] = [
  {
    id: 1,
    id_revendeur: 10,
    created_by: 6,
    id_instance: 1,
    name: "John Doe",
    position: "CEO",
    email: "john.doe@example.com",
    address: "123 Main Street"
  },
  {
    id: 2,
    id_revendeur: 15,
    created_by: 7,
    id_instance: 2,
    name: "Jane Smith",
    position: "CTO",
    email: "jane.smith@example.com",
    address: "456 Side Avenue"
  }
];

// Données initiales vides
const initialClientData: FinalClientData[] = [];

/**
 * **useFinalClient**:
 * Un hook personnalisé pour gérer les données des clients finaux, l'état de chargement et les erreurs.
 * Il récupère les données clients depuis une API externe et gère l'authentification via un token stocké dans le localStorage.
 *
 * @returns {Object} - Les données des clients finaux, l'état de chargement, le message d'erreur et le setter des données client.
 *
 * @property {FinalClientData[]} clientData - Données des clients finaux récupérées via l'API ou données de secours.
 * @property {Function} setClientData - La fonction pour définir manuellement les données des clients finaux.
 * @property {boolean} loading - Indique si les données sont en cours de récupération.
 * @property {string | null} error - Le message d'erreur ou null s'il n'y a pas d'erreur.
 *
 * @example
 * const { clientData, loading, error } = useFinalClient();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ClientList data={clientData} />;
 */
const useFinalClient = () => {
  const [clientData, setClientData] = useState<FinalClientData[]>(initialClientData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/ipbx/clientfinal', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Utilise le token
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setClientData(data);

      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // setClientData(fallBackClientData); // Utilise des données fallback en cas d'erreur
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    clientData,
    setClientData,
    loading,
    error
  };
};

export default useFinalClient;
