import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge, { BadgeBg } from "components/base/Badge"; // Import BadgeBg for proper type casting
import { PhoneUserData } from "hooks/api/ipbx/usePhoneUser";
import React, { useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { formatDistanceToNow, isBefore, subMinutes } from "date-fns";
import { fr } from "date-fns/locale";
import Avatar from "components/base/Avatar";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import { usePhoneConfigData } from "data/iper-x/context/PhoneConfigurationContext";

export const phoneUserTableColumns: ColumnDef<PhoneUserData>[] = [
  {
    accessorFn: ({ lastActivity }) => lastActivity,
    id: 'status',
    header: 'Utilisateur',
    cell: ({ row: { original } }) => {
      const { lastActivity, firstname, lastname, extension, id } = original;

      const isOnline = isBefore(
        subMinutes(new Date(), 5),
        new Date(lastActivity ?? Date.now())
      );

      const avatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(firstname)}+${encodeURIComponent(lastname)}&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center">
          <Avatar
            src={avatar}
            size="m"
            className="me-1"
            status={isOnline ? "online" : "offline"}
          />
          <div className="d-flex flex-column ms-1">
            <p className="mb-0 fw-bold text-dark fs-8">
              {firstname} {lastname}
            </p>
            <small className="text-muted">
              {extension}
            </small>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "8%", fontSize: "11px" }
      },
      cellProps: { className: "ps-2" }
    }
  },
  {
    accessorFn: (row) => row.idClient,
    id: 'idClient',
    header: 'Client',
    cell: ({ row: { original } }) => {
      const clientData = useClientData();
      const { idClient } = original;
      const client = clientData.find(client => client.id === idClient);
      return client ? client.name : "Client non trouvé";
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'mobile',
    header: 'Mobile',
    cell: ({ getValue }) => {
      const mobile = getValue() as string | null;
      return mobile ? <a href={`tel:${mobile}`}>{mobile}</a> : null;
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'fixe',
    header: 'Fixe',
    cell: ({ getValue }) => {
      const fixe = getValue() as string | null;
      return fixe ? <a href={`tel:${fixe}`}>{fixe}</a> : null;
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'lastActivity',
    header: 'Activité',
    cell: ({ getValue }) => {
      const lastActivity = getValue() as string | number;
      const formattedTimeAgo = formatDistanceToNow(new Date(lastActivity), { addSuffix: true, locale: fr });

      return <span>{formattedTimeAgo}</span>;
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'callStatus',
    header: 'Statut D’appel',
    cell: ({ row: { original } }) => {
      const { callStatus } = original;
      const statusCallMapping = {
        online: { color: "success", label: "En ligne" },
        waiting: { color: "warning", label: "En attente" },
        unregistered: { color: "danger", label: "Non inscrit" }
      };

      const { color, label } = statusCallMapping[callStatus as keyof typeof statusCallMapping] ?? {
        color: "secondary",
        label: "Inconnu"
      };

      return <Badge bg={color as BadgeBg} className="text-uppercase">{label}</Badge>;  
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorFn: (row) => row.idPhoneConfig,
    id: 'idPhoneConfig',
    header: 'Sous domaine instance',
    cell: ({ row: { original } }) => {
      const phoneConfigData = usePhoneConfigData();
      const { idPhoneConfig } = original;
      const phoneConfig = phoneConfigData.find(phoneConfig => phoneConfig.id === idPhoneConfig);
      return phoneConfig ? phoneConfig.hostSip : "Phone config non trouvé";
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'userStatus',
    header: 'Statut Utilisateur',
    cell: ({ row: { original } }) => {
      const { userStatus } = original;
      const statusUserMapping = {
        actif: { color: 'success', label: 'Actif' },
        absent: { color: 'danger', label: 'Absent' },
        dnd: { color: 'dark', label: 'Ne pas déranger' },
        communication_trip: { color: 'info', label: 'En communication' },
        conference: { color: 'warning', label: 'En conférence' },
        occupe: { color: 'primary', label: 'Occupé' }
      };
      const { color, label } = statusUserMapping[userStatus as keyof typeof statusUserMapping] || { color: 'secondary', label: 'Inconnu' };

      return <Badge bg={color as BadgeBg} className="text-uppercase">{label}</Badge>;  {/* Cast `color` to `BadgeBg` */}
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'userFunction',
    header: 'Fonction',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'displayNumber',
    header: 'N° Affiché',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'displayName',
    header: 'Nom Affiché',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    id: 'leadDropdown',
    header: 'Actions',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedUser, setSelectedUser] = useState<PhoneUserData | null>(null);
      const [formData, setFormData] = useState<PhoneUserData | null>(null);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (user: PhoneUserData) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
      };

      const handleEditClick = (user: PhoneUserData) => {
        setSelectedUser(user);
        setFormData(user);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedUser) {
          await handleDelete(selectedUser.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };


      const [passwordError, setPasswordError] = useState<string | null>(null);

      const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
        if (!passwordRegex.test(password)) {
          setPasswordError(
            'Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre majuscule, une lettre minuscule, et un caractère spécial.'
          );
        } else {
          setPasswordError(null);
        }
      };

      const handleDelete = async (id: number) => {

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        try {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Webrtc/phoneuser/${id}`,
            {
              method: 'DELETE',
              headers: {
                accept: 'text/plain',
                'Authorization': `Bearer ${token}`
              }
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la suppression de l'utilisateur",
            error
          );
        }
      };

      const handleEdit = async (data: PhoneUserData) => {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        if (passwordError) {
          return;
        }
        try {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Webrtc/phoneuser`,
            {
              method: 'PUT',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(data)
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            const errorData = await response.json();
            console.error('Error updating user:', errorData);
            alert('There was an error updating the user.');
          }
        } catch (error) {
          console.error(
            "Erreur lors de la mise à jour de l'utilisateur",
            error
          );
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>

          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Delete Modal */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cet utilisateur ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Edit Modal */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="firstname">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={formData?.firstname || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="lastname">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={formData?.lastname || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="extension">
                  <Form.Label>Poste interne</Form.Label>
                  <Form.Control
                    type="text"
                    name="extension"
                    value={formData?.extension || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData?.email || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="userFunction">
                  <Form.Label>Fonction</Form.Label>
                  <Form.Control
                    type="text"
                    name="userFunction"
                    value={formData?.userFunction || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="mobile">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    value={formData?.mobile || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="fixe">
                  <Form.Label>Fixe</Form.Label>
                  <Form.Control
                    type="text"
                    name="fixe"
                    value={formData?.fixe || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="phonePassword">
                  <Form.Label>Mot de passe (Téléphone)</Form.Label>
                  <Form.Control
                    type="password"
                    name="phonePassword"
                    value={formData?.phonePassword || ''}
                    onChange={handleChange}
                    isInvalid={!!passwordError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="displayNumber">
                  <Form.Label>N° Affiché</Form.Label>
                  <Form.Control
                    type="text"
                    name="displayNumber"
                    value={formData?.displayNumber || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="displayName">
                  <Form.Label>Nom Affiché</Form.Label>
                  <Form.Control
                    type="text"
                    name="displayName"
                    value={formData?.displayName || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="userStatus">
                  <Form.Label>Statut Utilisateur</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    name="userStatus"
                    value={formData?.userStatus || ""}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Sélectionner le statut utilisateur</option>
                    <option value="actif">Actif</option>
                    <option value="absent">Absent</option>
                    <option value="dnd">Ne pas déranger</option>
                    <option value="occupe">Occupé</option>
                    <option value="communication_trip">En communication</option>
                    <option value="conference-trip">En conférence</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const UserPhoneTableAPI = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9 text-center' }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UserPhoneTableAPI;
