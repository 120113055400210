import { useState, useCallback, useEffect } from "react";

export interface FinalClientData {
  id: number;
  idRevendeur: number;
  createdBy: number;
  idInstance: number;
  name: string;
  position: string;
  email: string;
  address: string;
  siret: string;
}

// Données de secours au cas où il y a une erreur de récupération des données
const fallBackClientData: FinalClientData = {
  id: 1,
  idRevendeur: 10,
  createdBy: 6,
  idInstance: 1,
  name: "John Doe",
  position: "CEO",
  email: "john.doe@example.com",
  address: "123 Main Street",
  siret: "321 569 452"
};

// Données initiales vides
const initialClientData: FinalClientData | null = null;

/**
 * **useFinalClient**:
 * Un hook personnalisé pour gérer les données d'un client final, l'état de chargement et les erreurs.
 * Il récupère les données du client depuis une API externe et gère l'authentification via un token stocké dans le localStorage.
 *
 * @param {number} id - L'identifiant du client final à récupérer.
 * @returns {Object} - Les données du client final, l'état de chargement, le message d'erreur et le setter des données du client.
 *
 * @property {FinalClientData | null} finalClientData - Données du client final récupérées via l'API ou données de secours.
 * @property {Function} setFinalClientData - La fonction pour définir manuellement les données du client final.
 * @property {boolean} loading - Indique si les données sont en cours de récupération.
 * @property {string | null} error - Le message d'erreur ou null s'il n'y a pas d'erreur.
 *
 * @example
 * const { finalClientData, loading, error } = useFinalClient(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ClientDetail data={finalClientData} />;
 */
const useFinalClientByID = (id: number) => {
  const [finalClientData, setFinalClientData] = useState<FinalClientData | null>(initialClientData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/ipbx/clientfinal/${id}`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}` // Utilise le token
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setFinalClientData(data);

      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setFinalClientData(fallBackClientData); // Utilise des données fallback en cas d'erreur
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    finalClientData,
    setFinalClientData,
    loading,
    error
  };
};

export default useFinalClientByID;
