import { faFileImport, faFileExport, faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import useSchedule from "hooks/api/ipbx/useSchedule";
import ScheduleTableAPI, { scheduleAPITableColumns } from "./ScheduleTableAPI";
import LeadsFilterModal from "components/modals/LeadsFilterModal";
import AddScheduleForm from "./AddScheduleForm";
import { ClientProvider } from "data/iper-x/context/ClientContext";
import useFinalClient from "hooks/api/ipbx/useFinalClient";

const ScheduleListAPI = () => {
  const { scheduleData, setScheduleData, loading, error } = useSchedule();
  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const table = useAdvanceTable({
    data: scheduleData,
    columns: scheduleAPITableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <ClientProvider clientData={clientData}>
    <div>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Plans d'appel</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="primary" onClick={() =>
              setShowAddClientModal(true)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter un plan d'appel
            </Button>
            <LeadsFilterModal
              show={openFilterModal}
              handleClose={() => setOpenFilterModal(false)}
            />
            </Col>
          </Row>
        </div>
        {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!loading && (
        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <ScheduleTableAPI />
          </div>
        </AdvanceTableProvider>
      )}
      <Modal show={showAddClientModal} onHide={() => setShowAddClientModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un plan d'appel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddScheduleForm
            tableData={scheduleData}
            setTableData={setScheduleData}
            onClose={() => setShowAddClientModal(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
    </ClientProvider>
  );
};

export default ScheduleListAPI;
