import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { RetailerClientDataType } from "hooks/api/retailer/useRetailerClients";
import { useNavigate } from "react-router-dom";

export const retailerClientsTableColumns: ColumnDef<RetailerClientDataType>[] = [
  {
    accessorKey: "clientFinal",
    header: "Nom de la société",
    cell: ({ row: { original } }) => {
      const { name } = original.clientFinal || {};
      const navigate = useNavigate();

      const handleCompanyClick = () => {
        localStorage.setItem("ipbx_access", JSON.stringify([{
          "ref_ipbx": name || "default_ipbx",
          "id_client_final": original.clientFinal.id || "default_id_client_final",
          "id_licence": original.idLicences || "default_id_licence",
          "revendeur_id": original.clientFinal.idRevendeur || "default_revendeur-id"
        }]));


        navigate("/ipbx/dashboard");
        window.location.reload();
      };

      return (
        <div className="d-flex align-items-center">
          <span
            className="fs-8 fw-bold text-primary cursor-pointer"
            onClick={handleCompanyClick}
          >
            {name}
          </span>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "25%" },
        className: "ps-4 pe-5 border-end"
      },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },
  {
    accessorKey: "erpProductRef",
    header: "Référence produit ERP",
    cell: ({ row: { original } }) => {
      const { erpProductRef } = original;
      return <p className="mb-0">{erpProductRef || "Non spécifié"}</p>;
    },
    meta: {
      headerProps: {
        style: { width: "25%" },
        className: "ps-4 pe-5 border-end"
      },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },
  {
    accessorKey: "idLicencesTypesHost",
    header: "Type de licence",
    cell: ({ row: { original } }) => {
      const { idLicencesTypesHost } = original;
      return <p className="mb-0">{idLicencesTypesHost === 1 ? "Hébergé" : "Non hébergé"}</p>;
    },
    meta: {
      headerProps: {
        style: { width: "15%" },
        className: "ps-4 pe-5 border-end"
      },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },
  {
    accessorKey: "key",
    header: "Numéro de licence",
    cell: ({ row: { original } }) => {
      const { key } = original;
      return <p className="mb-0">{key}</p>;
    },
    meta: {
      headerProps: {
        style: { width: "15%" },
        className: "ps-4 pe-5 border-end"
      },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },
  {
    accessorKey: "subscriptionDate",
    header: "Date de subscription",
    cell: ({ row: { original } }) => {
      const { subscriptionDate } = original;
      return <p className="mb-0">{subscriptionDate || "Non spécifié"}</p>;
    },
    meta: {
      headerProps: {
        style: { width: "15%" },
        className: "ps-4 pe-5 border-end"
      },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },
  {
    accessorKey: "expirationDate",
    header: "Date d'expiration",
    cell: ({ row: { original } }) => {
      const { expirationDate } = original;
      return <p className="mb-0">{expirationDate}</p>;
    },
    meta: {
      headerProps: {
        style: { width: "15%" },
        className: "ps-4 pe-5 border-end"
      },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  }
];

const RetailerClientsTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default RetailerClientsTable;
