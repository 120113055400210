import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import { TrunkData } from "hooks/api/ipbx/useTrunk";
import React, { useState } from "react";
import { Button, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";


const PasswordCell = ({ password }: { password: string }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  return (
    <div className="">
      <span className="me-2">
        {showPassword ? password : "••••••••"}
      </span>
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        onClick={toggleShowPassword}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};


export const trunksAPITableColumns: ColumnDef<TrunkData>[] = [
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "6%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorFn: (row) => row.idClient,
    id: 'idClient',
    header: 'Client',
    cell: ({ row: { original } }) => {
      const clientData = useClientData();
      const { idClient } = original;
      const client = clientData.find(client => client.id === idClient);
      return client ? client.name : "Client non trouvé";
    },
    meta: {
      headerProps: { style: { width: '12%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: "username",
    header: "User Trunk",
    meta: {
      headerProps: { style: { width: "7%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "password",
    header: "Mot de passe",
    cell: ({ row: { original } }) => (
      <PasswordCell password={original.password} />
    ),
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "server",
    header: "Serveur",
    meta: {
      headerProps: { style: { width: "6%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "nameInstance",
    header: "Instance",
    meta: {
      headerProps: { style: { width: "7%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "subdomainInstance",
    header: "Sous domaine instance",
    meta: {
      headerProps: { style: { width: "7%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // {
  //   accessorFn: (row) => row.idPhoneConfig,
  //   id: 'idPhoneConfig',
  //   header: 'Sous domaine instance',
  //   cell: ({ row: { original } }) => {
  //     const phoneConfigData = usePhoneConfigData();
  //     const { idPhoneConfig } = original;
  //     const phoneConfig = phoneConfigData.find(phoneConfig => phoneConfig.id === idPhoneConfig);
  //     return phoneConfig ? phoneConfig.hostSip : "Phone config non trouvé";
  //   },
  //   meta: {
  //     headerProps: { style: { width: '8%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  {
    accessorKey: "ipInstance",
    header: "IP Instance",
    meta: {
      headerProps: { style: { width: "7%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "currentCall",
    header: "Appels simult.",
    meta: {
      headerProps: { style: { width: "7%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayName",
    header: "N° affichage appel",
    meta: {
      headerProps: { style: { width: "7%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayNumber",
    header: "Nom affichage appel",
    meta: {
      headerProps: { style: { width: "7%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "prefixe",
    header: "Préfixe",
    meta: {
      headerProps: { style: { width: "6%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "ringDuration",
    header: "Sonnerie",
    meta: {
      headerProps: { style: { width: "6%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "withRegistration",
    header: "Avec REG",
    cell: ({ row: { original } }) => {
      const { withRegistration } = original;
      return (
        <Badge
          bg={withRegistration ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {withRegistration ? "Oui" : "Non"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "6%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "sdaRegistration",
    header: "SDA REG",
    meta: {
      headerProps: { style: { width: "6%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "État trunk",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "8%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "stateRegister",
    header: "État REGISTER",
    cell: ({ row: { original } }) => {
      const { stateRegister } = original;
      return (
        <Badge
          bg={stateRegister ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {stateRegister ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "8%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "nbLiveCalls",
    header: "Nbr d'appel en cours",
    meta: {
      headerProps: { style: { width: "7%", fontSize: '11px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedTrunk, setSelectedTrunk] = useState<TrunkData | null>(null);
      const [formData, setFormData] = useState<TrunkData | null>(null);
      const [passwordError, setPasswordError] = useState<string | null>(null);
      const [showPassword, setShowPassword] = useState(false);

      const toggleShowPassword = () => {
        setShowPassword((prevShow) => !prevShow);
      };

      const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
        if (!passwordRegex.test(password)) {
          setPasswordError(
            'Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre majuscule, une lettre minuscule, et un caractère spécial.'
          );
        } else {
          setPasswordError(null);
        }
      };

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (sda: TrunkData) => {
        setSelectedTrunk(sda);
        setShowDeleteModal(true);
      };

      const handleEditClick = (sda: TrunkData) => {
        setSelectedTrunk(sda);
        setFormData(sda);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedTrunk) {
          await handleDelete(selectedTrunk.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/sda/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
              'Authorization': `Bearer ${token}`
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de la règle entrante", error);
        }
      };

      const handleEdit = async (data: TrunkData) => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          if (passwordError) {
            return;
          }
          const { id, ...dataWithoutId } = data;
          const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/trunk', {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour de la règle entrante", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cette règle entrante ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le trunk</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="name">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData?.name || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="server">
                  <Form.Label>Serveur</Form.Label>
                  <Form.Control
                    type="text"
                    name="server"
                    value={formData?.server || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="username">
                  <Form.Label>Nom d'utilisateur</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={formData?.username || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Mot de passe (Téléphone)</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="phonePassword"
                      value={formData?.password || ''}
                      onChange={handleChange}
                      isInvalid={!!passwordError}
                      required
                    />
                    <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="displayName">
                  <Form.Label>Nom affichage appel</Form.Label>
                  <Form.Control
                    type="text"
                    name="displayNumber"
                    value={formData?.displayName || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="displayNumber">
                  <Form.Label>N° affichage appel</Form.Label>
                  <Form.Control
                    type="text"
                    name="displayNumber"
                    value={formData?.displayNumber || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="prefixe">
                  <Form.Label>Préfixe</Form.Label>
                  <Form.Control
                    type="text"
                    name="prefixe"
                    value={formData?.prefixe || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="ringDuration">
                  <Form.Label>Durée de sonnerie</Form.Label>
                  <Form.Control
                    type="number"
                    name="ringDuration"
                    value={formData?.ringDuration || "20"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="withRegistration">
                  <Form.Label>Avec enregistrement</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="withRegistration"
                    checked={formData?.withRegistration || false}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="sdaRegistration">
                  <Form.Label>Enregistrement SDA</Form.Label>
                  <Form.Control
                    type="text"
                    name="sdaRegistration"
                    value={formData?.sdaRegistration || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const TrunksTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default TrunksTableAPI;
