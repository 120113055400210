import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFinalClient from "hooks/api/ipbx/useFinalClient";
import { VoicemailData } from "hooks/api/ipbx/useVoicemail";
import React, { ChangeEvent, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

type VoicemailFormProps = {
  tableData: VoicemailData[];
  setTableData: React.Dispatch<React.SetStateAction<VoicemailData[]>>;
  onClose: () => void;
};


const AddVoicemailForm: React.FC<VoicemailFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newVoicemail, setNewVoicemail] = useState<VoicemailData>({
    id: 0,
    idClient: 0,
    voicemailNumber: 0,
    emailUser: "",
    password: "",
    nameVoicemail: "",
    state: false
  });

  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        'Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre majuscule, une lettre minuscule, et un caractère spécial.'
      );
    } else {
      setPasswordError(null);
    }
  };


  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const parsedValue = (name === 'idClient' || name === "voicemailNumber") ? parseInt(value, 10) : value;

    if (name === 'password' || name === 'phonePassword') {
      validatePassword(value);
    }

    setNewVoicemail(prevVoicemail => ({
      ...prevVoicemail,
      [name]: parsedValue
    }));
  };



  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (passwordError) {
      return;
    }
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/voicemail', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
          ,
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newVoicemail)
      });

      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: VoicemailData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un répondeur numérique", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>

      <Form.Group controlId="nameVoicemail">
        <Form.Label>Nom de la boîte vocale</Form.Label>
        <Form.Control
          type="text"
          name="nameVoicemail"
          value={newVoicemail.nameVoicemail}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="emailUser">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="emailUser"
          value={newVoicemail.emailUser}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Mot de passe</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={newVoicemail.password}
            onChange={handleChange}
            isInvalid={!!passwordError}
          />
          <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback type="invalid">
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="voicemailNumber">
        <Form.Label>Numéro de la boîte vocale</Form.Label>
        <Form.Control
          type="number"
          name="voicemailNumber"
          value={newVoicemail.voicemailNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newVoicemail.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewVoicemail({
              ...newVoicemail,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddVoicemailForm;
