import { useState, useEffect, useCallback } from 'react';

export interface TrunkData {
  id: number;
  name: string;
  idClient: number;
  server: string;
  username: string;
  password: string;
  nameInstance: string;
  subdomainInstance: string;
  ipInstance: string;
  idPhoneConfig: number;
  caps: number;
  currentCall: number;
  displayName: string;
  displayNumber: string;
  prefixe: string;
  ringDuration: number;
  withRegistration: boolean;
  sdaRegistration: string;
  stateRegister: boolean;
  state: boolean;
  nbLiveCalls: number;
}

const fallBackTrunkTableData: TrunkData[] = [
  {
    id: 1,
    name: "Main Trunk",
    idClient: 1,
    username: "main_trunk_user",
    password: "securepassword123",
    server: "sip.maintrunkserver.com",
    nameInstance: "MainInstance",
    subdomainInstance: 'ipercom.io',
    ipInstance: 'string',
    idPhoneConfig: 1,
    caps: 50,
    currentCall: 10,
    displayName: 'name',
    displayNumber: "+1234567890",
    prefixe: "001",
    ringDuration: 30,
    withRegistration: true,
    sdaRegistration: "+1234567890",
    stateRegister: true,
    state: false,
    nbLiveCalls: 2
  },

];

const trunkTableData: TrunkData[] = [];

/**
 * **Trunk SIP** :
 * useTrunk is a custom hook that manages SIP trunk data, loading state, and error state.
 * It fetches the trunk data from an external API and handles the token from localStorage.
 *
 * @returns {Object} The trunk data, loading state, error message, and setter for trunk data.
 *
 * @property {TrunkData[]} trunkData - The trunk data fetched from the API or fallback.
 * @property {Function} setTrunkData - The function to manually set the trunk data.
 * @property {boolean} loading - The loading state indicating whether data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 */
const useTrunk = () => {
  const [trunkData, setTrunkData] = useState<TrunkData[]>(trunkTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTrunks = useCallback(async () => {
    try {
      // Get token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/trunk', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}`, // Add the token to the headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setTrunkData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // setTrunkData(fallBackTrunkTableData); // Fallback to default data in case of error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTrunks();
  }, [fetchTrunks]);

  return {
    trunkData,
    setTrunkData,
    loading,
    error,
  };
};

export default useTrunk;
