import { PhoneConfigData } from "hooks/api/ipbx/usePhoneConfig";
import React, { createContext, useContext, ReactNode } from 'react';




// Création du contexte pour les données clients
const PhoneConfigurationContext = createContext<PhoneConfigData[] | null>(null);

// Hook pour utiliser le contexte client
export const usePhoneConfigData = () => {
  const context = useContext(PhoneConfigurationContext);
  if (!context) {
    throw new Error('usePhoneConfigData must be used within a ClientProvider');
  }
  return context;
};

// Fournisseur de contexte pour les données clients
export const PhoneConfigProvider = ({
  phoneConfigData,
  children,
}: {
  phoneConfigData: PhoneConfigData[];
  children: ReactNode;
}) => {
  return (
    <PhoneConfigurationContext.Provider value={phoneConfigData}>
      {children}
    </PhoneConfigurationContext.Provider>
  );
};
