import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TrunkData } from "hooks/api/ipbx/useTrunk";
import React, { ChangeEvent, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

type TrunkFormProps = {
  tableData: TrunkData[];
  setTableData: React.Dispatch<React.SetStateAction<TrunkData[]>>;
  onClose: () => void;
};

const AddTrunkSIPForm: React.FC<TrunkFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newTrunk, setNewTrunk] = useState<TrunkData>({
    id: 0,
    name: '',
    idClient: 0,
    username: '',
    password: '',
    server: '',
    nameInstance: '',
    subdomainInstance: '',
    ipInstance: '',
    idPhoneConfig: 0,
    caps: 0,
    currentCall: 0,
    displayName: '',
    displayNumber: '',
    prefixe: '',
    ringDuration: 0,
    withRegistration: true,
    sdaRegistration: '',
    stateRegister: true,
    state: true,
    nbLiveCalls: 0
  });

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false)
  const [sdaInput, setSdaInput] = useState<string>("");
  const [sdaList, setSdaList] = useState<string[]>([]); 
  const [sdaError, setSdaError] = useState<string | null>(null); // Error state for SDA input

  // Fonction pour ajouter un numéro SDA à la liste
  const addSdaToList = () => {
    if (sdaInput) {
      const isValidSDA = /^\d{10}$/.test(sdaInput); // Check if SDA is 10 digits

      if (isValidSDA) {
        setSdaList((prevSdaList) => [...prevSdaList, sdaInput]);
        setSdaInput(''); // Réinitialise le champ après l'ajout
        setSdaError(null); // Clear error if input is valid
      } else {
        setSdaError('Le numéro SDA doit contenir exactement 10 chiffres.');
      }
    }
  };

  const handleDeleteSda = (sdaToDelete: string) => {
    setSdaList((prevSdaList) => prevSdaList.filter((sda) => sda !== sdaToDelete));
  };


  const toggleShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        'Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre majuscule, une lettre minuscule, et un caractère spécial.'
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    if (name === 'password') {
      validatePassword(value);
    }

    setNewTrunk(prevTrunk => ({
      ...prevTrunk,
      [name]: value
    }));
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

     // Créer une chaîne de numéros SDA séparés par des virgules
     const sdaRegistrationString = sdaList.join(',');

     // Mettre à jour newTrunk avec cette chaîne
     setNewTrunk((prevTrunk) => ({
       ...prevTrunk,
       sdaRegistration: sdaRegistrationString,
     }));

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/trunk', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newTrunk)
      });
      if (response.ok) {
        window.location.reload();
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: TrunkData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout du trunk", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newTrunk.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="server">
        <Form.Label>Serveur</Form.Label>
        <Form.Control
          type="text"
          name="server"
          value={newTrunk.server}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="username">
        <Form.Label>Nom d'utilisateur Trunk</Form.Label>
        <Form.Control
          type="text"
          name="username"
          value={newTrunk.username}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Mot de passe</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? 'text' : 'password'} 
            name="password"
            value={newTrunk.password}
            onChange={handleChange}
            isInvalid={!!passwordError}
          />
          <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback type="invalid">
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="currentCall">
        <Form.Label>Nombre d'appels simultanés</Form.Label>
        <Form.Control
          type="number"
          name="currentCall"
          value={newTrunk.currentCall}
          onChange={handleChange}
          required
        />
      </Form.Group>



      <Form.Group controlId="displayName">
        <Form.Label>Nom affichage appel</Form.Label>
        <Form.Control
          type="text"
          name="displayName"
          value={newTrunk.displayName}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="displayNumber">
        <Form.Label>N° affichage appel</Form.Label>
        <Form.Control
          type="text"
          name="displayNumber"
          value={newTrunk.displayNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="prefixe">
        <Form.Label>Préfixe</Form.Label>
        <Form.Control
          type="text"
          name="prefixe"
          value={newTrunk.prefixe}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="ringDuration">
        <Form.Label>Durée de sonnerie</Form.Label>
        <Form.Control
          type="number"
          name="ringDuration"
          value={newTrunk.ringDuration}
          onChange={handleChange}
          required
        />
      </Form.Group>
      
      <Form.Group controlId="withRegistration">
        <Form.Label>Avec enregistrement</Form.Label>
        <Form.Check
          type="checkbox"
          name="withRegistration"
          checked={newTrunk.withRegistration}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewTrunk({
              ...newTrunk,
              withRegistration: e.target.checked
            })
          }
        />
      </Form.Group>


      <Form.Group controlId="sdaRegistration">
        <Form.Label>Ajouter un SDA avec format international E.164. exp: 331XXXXXXX</Form.Label>
        <InputGroup>
          <Form.Control
            type="text"
            name="sdaRegistration"
            value={sdaInput}
            onChange={(e) => setSdaInput(e.target.value)}
            placeholder="Entrez un numéro SDA"
          />
          <Button onClick={addSdaToList} variant="success">
            Ajouter
          </Button>
        </InputGroup>
        {sdaError && <Form.Text className="text-danger">{sdaError}</Form.Text>}
      </Form.Group>

      {sdaList.length > 0 && (
        <div>
          <p className="fs-9">Numéros SDA ajoutés :</p>
          <ul>
            {sdaList.map((sda, index) => (
              <li key={index} className="fs-9">
                {sda}{" "}
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={() => handleDeleteSda(sda)}
                  style={{ cursor: "pointer", color: "red" }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      
      <Form.Group controlId="nbLiveCalls">
        <Form.Label>Nombre d'appels en direct</Form.Label>
        <Form.Control
          type="number"
          name="nbLiveCalls"
          value={newTrunk.nbLiveCalls}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddTrunkSIPForm;
