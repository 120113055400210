import useFinalClient from "hooks/api/ipbx/useFinalClient";
import { RoutingData } from "hooks/api/ipbx/useRouting";
import useSchedule from "hooks/api/ipbx/useSchedule";
import React, { ChangeEvent, useState } from "react";
import { Button, Form } from "react-bootstrap";

type RoutingFormProps = {
  tableData: RoutingData[];
  setTableData: React.Dispatch<React.SetStateAction<RoutingData[]>>;
  onClose: () => void;
};

const AddRoutingForm: React.FC<RoutingFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newRouting, setNewRouting] = useState<RoutingData>({
    id: 0,
    nom: "",
    prefixe: "",
    idClient: 0,
    schedule: "",
    trunk: "",
    action: "",
    displayNumber: "",
    state: false
  });

  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();

  const { scheduleData, loading, error } = useSchedule();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewRouting(prevRouting => ({
      ...prevRouting,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/routing', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
          ,
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newRouting)
      });

      if (response.ok) {
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: RoutingData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'une règle de routage", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>

<Form.Group controlId="nom">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="nom"
          value={newRouting.nom}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="prefixe">
        <Form.Label>Préfixe</Form.Label>
        <Form.Control
          type="text"
          name="prefixe"
          value={newRouting.prefixe}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="schedule">
        <Form.Label>Horaire</Form.Label>
        <Form.Control
          as="select"
          name="schedule"
          className="form-select"
          value={newRouting.schedule}
          onChange={handleChange}
          required
          disabled={loading}
        >
          <option value="">Sélectionnez un horaire</option>
          {scheduleData.map((schedule) => (
            <option key={schedule.id} value={schedule.name}>
              {schedule.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="trunk">
        <Form.Label>Trunk</Form.Label>
        <Form.Control
          type="text"
          name="trunk"
          value={newRouting.trunk}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="action">
        <Form.Label>Action</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="action"
          value={newRouting.action}
          onChange={handleChange}
          required
        >
          <option value="authorized">Autoriser</option>
          <option value="blocked">Bloquer</option>

        </Form.Control>
      </Form.Group>

      <Form.Group controlId="displayNumber">
        <Form.Label>Numéro d'affichage</Form.Label>
        <Form.Control
          type="text"
          name="displayNumber"
          value={newRouting.displayNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newRouting.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewRouting({
              ...newRouting,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddRoutingForm;
