import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dummyStats } from "../../../data/crm/stats";
import { pricingColumnItems } from "../../../data/pricing";
import SocialProfileCard from "components/cards/SocialProfileCard";
import NavbarBottom from "components/modules/social/NavbarBottom";
import { useMainLayoutContext } from "providers/MainLayoutProvider";
// import profileImage from 'assets/img/team/59.webp';
import AnalyticsStatsEditor from "../../../components/stats/AnalyticstatsEditor";
import RetailerRanksProgressBar from "../../../components/progress-bars/RetailerRanksProgressBar";
import RetailerPricingColumnItem from "../../../components/pricing-items/RetailerPricingColumnItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import RetailerClients from "./clients/RetailerClients";
import LeadListAPI from "./leads/LeadListAPI";
import PageBreadcrumb, { PageBreadcrumbItem } from "../../../components/common/PageBreadcrumb";


export const revendeurBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail Revendeur',
    url: '/dashboard/revendeur'
  }
];

const DashboardRetailer = () => {
  const { setFooterClass } = useMainLayoutContext();

  useEffect(() => {
    setFooterClass('d-none d-lg-block');
    return () => {
      setFooterClass('');
    };
  }, []);

  return (
    <>
      <Col md={9} xs={12} className="mb-4">
        <PageBreadcrumb items={revendeurBreadcrumbItems} />
        <h2 className="mb-4 text-1100">Portail Revendeur</h2>
      </Col>
      <Row className="gy-3 gx-5 gx-xxl-6">
        <Col lg={5} xl={4}>
          <SocialProfileCard
            showAbout={true}
            avatar={""}
            className="mb-4"
          />
        </Col>
        <Col md={8} xs={12}>
          <div className="px-3 mb-5 card">
            <AnalyticsStatsEditor stats={dummyStats} />
            <div className="ms-3">
              <RetailerRanksProgressBar current={0} target={6000} />
              <div className="d-flex flex-wrap mt-4 align-items-center">
                <h3 className="me-0">Objectif</h3>
              </div>
              <div className="mt-2">
                <p className="fw-semi-bold mb-0">
                  Obtenez le statut SILVER pour 6000€
                </p>
                <p className="fw-semi-bold mb-0">
                  (Objectif du 1er janvier au 31 décembre de l'année en cours)
                </p>
              </div>
              <div className="d-flex flex-wrap mt-4 align-items-center">
                <h3 className="me-0">Lien de parrainage</h3>
              </div>
              <div className="mt-2 mb-3">
                <p className="fw-semi-bold mb-0">
                  Ajoutez le lien suivant sur votre site web ou envoyez-le par
                  email à vos clients pour associer automatiquement le client à
                  votre compte
                </p>
                <Link to="https://ipercom.com/a-propos/devenir-revendeur">
                  https://ipercom.com/a-propos/devenir-revendeur
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="px-3 mb-3 px-lg-10 pt-5 pb-5">
        <div className="mb-4">
          <Row>
            <Col xs={12} md={4} className="mt-5">
              <h2>Possibilité d'évolution</h2>
              <p>
                Choisissez l'option qui convient le mieux à votre entreprise.
              </p>
            </Col>
            <Col
              xs={12}
              md={8}
              className="d-flex flex-wrap justify-content-around"
            >
              {pricingColumnItems.map((item, index) => (
                <RetailerPricingColumnItem key={index} pricing={item} />
              ))}
            </Col>
          </Row>
        </div>
        <div className="card d-none d-md-block pt-n5 mt-2 pb-n4">
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Remise sur licences
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0" style={{ color: '#CD1F90' }}>
              <div className="col px-2 py-4" style={{ color: '#B4B7C9' }}>
                  <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">8%</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '1rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">15%</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">20%</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '1rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Certification expert  (Commerciale et technique)
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Accès au forum partenaires
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Licence iperx de démonstration accès simultanée {' '}
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0" style={{ color: '#CD1F90' }}>
                <div className="col px-2 py-4">Nfr *4 </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">Nfr *8</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">Nfr *16</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">Nfr *32</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Interface revendeur personnalisée 
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Support tickets par/an
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0" style={{ color: '#CD1F90' }}>
                <div className="col px-2 py-4">5</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">10</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">Support illimité</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    Support prioritaire & illimité
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Hébergement inclus
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '-1px', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Inscription du revendeur sur notre site web
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Leads iperx
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: '#B4B7C9' }}>
                  <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-2 border-end">
              Formation technique et commerciale 2 jours 1250 € HT/jour
              Elligible OPCO Qualiopi
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-2 border-end">
              Animations commerciales, campagne marketing, accompagnement
              commercial
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0">
                <div className="col px-2 py-4" style={{ color: '#B4B7C9' }}>
                  <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 border-end">
              Ventes annuelles minimales{' '}
            </div>
            <div className="col-md-8 text-center">
              <div className="row row-cols-4 g-0" style={{ color: '#CD1F90' }}>
                <div className="col px-2 py-4">6 000 € ht/an </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">12 000 € ht/an</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">60 000 € ht/an </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">125 000 € ht/an</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-4">
          * Licence Nfr exclusivement commerciale non revendable
        </p>
      </div>

      <LeadListAPI />

      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-6 pb-3 border-y border-300">
        <Row className="gx-6">
          <RetailerClients />
          <Col
            md={6}
            lg={12}
            xl={6}
            className="mb-5 mb-md-3 mb-lg-5 mb-xl-2 mb-xxl-3"
          ></Col>
          <Col md={6} lg={12} xl={6} className="mb-1 mb-sm-0"></Col>
        </Row>
      </div>
      <NavbarBottom active="home" className="d-lg-none" />
    </>
  );
};

export default DashboardRetailer;
