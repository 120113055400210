import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Button, Form, Modal, Alert } from 'react-bootstrap';

interface User {
  assigne: string;
  startDate: string;
  endDate: string;
  type_code: string;
  category_code: string;
  severity_code: string;
  socid: string;
  projectid: string;
  subject: string;
  message: string;
}

interface DictionaryItem {
  id: string;
  label: string;
}

interface TicketFormProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (user: User) => void;
}

const TicketForm: React.FC<TicketFormProps> = ({ show, handleClose, handleSubmit }) => {
  const [user, setUser] = useState<User>({
    assigne: '',
    startDate: '',
    endDate: '',
    type_code: '',
    category_code: '',
    severity_code: '',
    socid: '',
    projectid: '',
    subject: '',
    message: ''
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [users, setUsers] = useState<DictionaryItem[]>([]);
  const [thirdParties, setThirdParties] = useState<DictionaryItem[]>([]);
  const [projects, setProjects] = useState<DictionaryItem[]>([]);
  const [categories, setCategories] = useState<DictionaryItem[]>([]);
  const [severities, setSeverities] = useState<DictionaryItem[]>([]);
  const [types, setTypes] = useState<DictionaryItem[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const DOLID = localStorage.getItem('DOLID');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await Promise.all([
      fetchUsers(),
      fetchThirdParties(),
      fetchProjects(),
      fetchCategories(),
      fetchSeverities(),
      fetchTypes()
    ]);
  };

  const fetchWithApiKey = async (url: string) => {
    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'DOLAPIKEY': DOLAPIKEY || '',
      },
    });
    return response.json();
  };

  const fetchUsers = async () => {
    try {
      const data = await fetchWithApiKey('https://erp.ipercom.io/api/index.php/users');
      setUsers(data.map((user: any) => ({ id: user.rowid, label: `${user.firstname} ${user.lastname}` })));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchThirdParties = async () => {
    try {
      const data = await fetchWithApiKey('https://erp.ipercom.io/api/index.php/thirdparties');
      setThirdParties(data.map((party: any) => ({ id: party.id, label: party.name })));
    } catch (error) {
      console.error("Error fetching third parties:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const data = await fetchWithApiKey('https://erp.ipercom.io/api/index.php/projects');
      setProjects(data.map((project: any) => ({ id: project.id, label: project.ref })));
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const data = await fetchWithApiKey('https://erp.ipercom.io/api/index.php/setup/dictionary/ticket_categories');
      setCategories(data.map((category: any) => ({ id: category.id, label: category.label })));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSeverities = async () => {
    try {
      const data = await fetchWithApiKey('https://erp.ipercom.io/api/index.php/setup/dictionary/ticket_severities');
      setSeverities(data.map((severity: any) => ({ id: severity.id, label: severity.label })));
    } catch (error) {
      console.error("Error fetching severities:", error);
    }
  };

  const fetchTypes = async () => {
    try {
      const data = await fetchWithApiKey('https://erp.ipercom.io/api/index.php/setup/dictionary/ticket_types');
      setTypes(data.map((type: any) => ({ id: type.id, label: type.label })));
    } catch (error) {
      console.error("Error fetching types:", error);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('subject', user.subject);
    formData.append('message', user.message);
    formData.append('startDate', user.startDate);
    formData.append('endDate', user.endDate);
    formData.append('type_code', user.type_code);
    formData.append('category_code', user.category_code);
    formData.append('severity_code', user.severity_code);
    formData.append('socid', DOLID ?? ''); // Use empty string as default if DOLID is null

    formData.append('projectid', user.projectid);
    formData.append('assigne', user.assigne);

    if (file) {
      formData.append('addedfile', file);
    }

    try {
      const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
      const response = await fetch('https://erp.ipercom.io/api/index.php/tickets', {
        method: 'POST',
        headers: {
          'DOLAPIKEY': DOLAPIKEY || '',
        },
        body: formData,
      });

      if (response.ok) {
        setSuccess('Ticket créé avec succès.');
        setError(null);
        setUser({
          assigne: '',
          startDate: '',
          endDate: '',
          type_code: '',
          category_code: '',
          severity_code: '',
          socid: '',
          projectid: '',
          subject: '',
          message: ''
        });
        setFile(null);
        handleSubmit(user);
      } else {
        const errorResponse = await response.json();
        setError(`Erreur: ${errorResponse.error || 'Une erreur s\'est produite.'}`);
        setSuccess(null);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(`Erreur: ${err.message}`);
      } else {
        setError('Erreur inconnue');
      }
      setSuccess(null);
    }
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="subject">
            <Form.Label>Sujet</Form.Label>
            <Form.Control
              type="text"
              name="subject"
              value={user.subject}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="message"
              value={user.message}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="file">
            <Form.Label>Fichiers joints</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
            />
            {file && <div className="mt-2">{file.name}</div>}
          </Form.Group>
          <Form.Group controlId="assigne">
            <Form.Label>Assigné à</Form.Label>
            <Form.Control
              as="select"
              name="assigne"
              value={user.assigne}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionnez un utilisateur</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>{user.label}</option>
              ))}
            </Form.Control>
          </Form.Group>

          {/*<Form.Group controlId="socid">*/}
          {/*  <Form.Label>Tiers</Form.Label>*/}
          {/*  <Form.Control*/}
          {/*    as="select"*/}
          {/*    name="socid"*/}
          {/*    value={user.socid}*/}
          {/*    onChange={handleChange}*/}
          {/*  >*/}
          {/*    <option value="">Sélectionnez un tiers</option>*/}
          {/*    {thirdParties.map(tp => (*/}
          {/*      <option key={tp.id} value={tp.id}>{tp.label}</option>*/}
          {/*    ))}*/}
          {/*  </Form.Control>*/}
          {/*</Form.Group>*/}
          {/*<Form.Group controlId="projectid">*/}
          {/*  <Form.Label>Projet</Form.Label>*/}
          {/*  <Form.Control*/}
          {/*    as="select"*/}
          {/*    name="projectid"*/}
          {/*    value={user.projectid}*/}
          {/*    onChange={handleChange}*/}
          {/*  >*/}
          {/*    <option value="">Sélectionnez un projet</option>*/}
          {/*    {projects.map(proj => (*/}
          {/*      <option key={proj.id} value={proj.id}>{proj.label}</option>*/}
          {/*    ))}*/}
          {/*  </Form.Control>*/}
          {/*</Form.Group>*/}

          <Form.Group controlId="category_code">
            <Form.Label>Groupe du ticket</Form.Label>
            <Form.Control
              as="select"
              name="category_code"
              value={user.category_code}
              onChange={handleChange}
            >
              <option value="">Sélectionnez un groupe</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>{category.label}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="type_code">
            <Form.Label>Type de demande</Form.Label>
            <Form.Control
              as="select"
              name="type_code"
              value={user.type_code}
              onChange={handleChange}
            >
              <option value="">Sélectionnez un type</option>
              {types.map(type => (
                <option key={type.id} value={type.id}>{type.label}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="severity_code">
            <Form.Label>Sévérité</Form.Label>
            <Form.Control
              as="select"
              name="severity_code"
              value={user.severity_code}
              onChange={handleChange}
            >
              <option value="">Sélectionnez une sévérité</option>
              {severities.map(severity => (
                <option key={severity.id} value={severity.id}>{severity.label}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit" className='mt-4'>
            Ajouter
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TicketForm;
