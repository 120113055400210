import { useCallback, useEffect, useState } from "react";

export interface RetailerClientDataType {
  idLicences: number;
  key: string;
  subscriptionDate: string;
  expirationDate: string;
  idLicencesCatalog: number;
  idCompanies: number;
  idLicencesTypesHost: number;
  idProduct: number;
  idLicenseParams: number;
  idErpCompanies: number;
  idErpOrder: number;
  idErpRefProduit: number;
  idResponsible: number;
  activationDate?: string | null;
  createdAt?: string | null;
  erpThiersRef?: string | null;
  erpOrderRef?: string | null;
  erpProductRef?: string | null;
  maxUsers?: number | null;
  simultaneousCalls?: number | null;
  subDuration?: number | null;
  status?: string | null;
  clientFinal: {
    id: number;
    idRevendeur: number;
    createdBy: number;
    idInstance: number;
    name: string;
    position: string;
    email: string;
    address: string;
  };
  ref_ipbx?: string;
  client_final_id?: string;
  licence_id?: string;
}

// Empty data to reset or initialize the table
const emptyRetailerClientsData: RetailerClientDataType[] = [];

const fallBackRetailerTableData: RetailerClientDataType[] = [
  {
    idLicences: 4,
    key: "KO3M-Q8I4-4MIV-VB4R",
    subscriptionDate: "2024-09-12",
    expirationDate: "2025-09-12",
    idLicencesCatalog: 1,
    idCompanies: 1,
    idLicencesTypesHost: 1,
    idProduct: 1,
    idLicenseParams: 1,
    idErpCompanies: 1,
    idErpOrder: 1,
    idErpRefProduit: 1,
    idResponsible: 1,
    activationDate: null,
    createdAt: null,
    erpThiersRef: null,
    erpOrderRef: null,
    erpProductRef: null,
    maxUsers: null,
    simultaneousCalls: null,
    subDuration: null,
    status: null,
    clientFinal: {
      id: 1,
      idRevendeur: 10,
      createdBy: 6,
      idInstance: 1,
      name: "Ipercom2",
      position: "undefined",
      email: "undefined",
      address: "undefined"
    }
  }
];

/**
 * **useRetailerClients**:
 * A custom hook that fetches and manages retailer client data, including loading and error states.
 * It retrieves client license information from an external API using token-based authentication stored in localStorage.
 * It also provides fallback default data and an empty state.
 *
 * @returns {Object} - The retailer client data, loading state, error message, and setter for table data.
 *
 * @property {RetailerClientDataType[]} tableData - Client data fetched from the API or fallback default data in case of errors.
 * @property {Function} setTableData - Function to manually set the client data.
 * @property {boolean} loading - Indicates whether the client data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 * @property {Function} fetchData - Function to refetch the client data.
 *
 * @example
 * // Example usage of useRetailerClients in a component
 * const { tableData, loading, error } = useRetailerClients();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ClientTable data={tableData} />;
 */
const useRetailerClients = () => {
  const [tableData, setTableData] = useState<RetailerClientDataType[]>(emptyRetailerClientsData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token non disponible');
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Licence/company/me`, {
        method: "GET",
        headers: {
          "accept": "text/plain",
          "Authorization": `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setTableData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // In case of error, load fallback data
      setTableData(fallBackRetailerTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    tableData,
    setTableData,
    fetchData,
    loading,
    error
  };
};

export default useRetailerClients;
