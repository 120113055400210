import { useState, useEffect, useCallback } from 'react';

export class UserData {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  office_phone: string;
  user_mobile: string;
  login: string;
  admin: string;
  socid: string;
  datelastlogin: number;

  constructor(data: any) {
    this.id = data.id;
    this.firstname = data.firstname || 'N/A';
    this.lastname = data.lastname || 'N/A';
    this.email = data.email || 'N/A';
    this.office_phone = data.office_phone || 'N/A';
    this.user_mobile = data.user_mobile || 'N/A';
    this.login = data.login || 'N/A';
    this.admin = data.admin || 'N/A';
    this.socid = data.socid || 'N/A';
    this.datelastlogin = data.datelastlogin || 0;
  }

  getFullName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  isAdmin(): boolean {
    return this.admin === '1';
  }

  getLastLoginDate(): string {
    return this.datelastlogin ? new Date(this.datelastlogin * 1000).toLocaleString() : 'Jamais';
  }
}

// Helper function to fetch detailed user info
export const fetchUserDetails = async (userId: number) => {
  try {
    const response = await fetch(`https://erp.ipercom.io/api/index.php/users/${userId}?includepermissions=1`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'DOLAPIKEY': 'UX8gfqaOcVv8692NNw11fkvE6Sh3DJ5M',
      },
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching user details", error);
    return null;
  }
};

const useRetailerUsers = () => {
  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(
        'https://erp.ipercom.io/api/index.php/users/groups/5?load_members=1',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!data.members || typeof data.members !== 'object') {
        throw new Error('Données des membres invalides.');
      }

      const membersArray = Object.values(data.members);

      // Fetch detailed information for each user
      const users = await Promise.all(
        membersArray.map(async (member: any) => {
          const detailedUser = await fetchUserDetails(member.id); // Fetch detailed data for each user
          return new UserData(detailedUser);
        })
      );

      setUsersData(users);
      setError(null);
    } catch (error: any) {
      console.error('Erreur lors de la récupération des données de l\'API', error);
      setError('Impossible de récupérer les données.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    usersData,
    loading,
    error,
  };
};

export default useRetailerUsers;
