import React, { useState, ChangeEvent, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { AppModule } from 'hooks/api/security/useAppModules';
import useAppModules from 'hooks/api/security/useAppModules';
import useModuleFeatures, { ModuleFeature } from 'hooks/api/security/useModuleFeatures';
import { UserData } from 'hooks/api/security/useEditorUser';

type UserFormProps = {
  tableData: UserData[];
  setTableData: React.Dispatch<React.SetStateAction<UserData[]>>;
  onClose: () => void;
};

const AddUserForm: React.FC<UserFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newUser, setNewUser] = useState<UserData>({
    idUsers: 0,
    firstname: '',
    lastname: '',
    mail: '',
    phone: '',
    mobile: '',
    avatar: '',
    userRole: { id: 14, name: '', slug: '' }, // ID de rôle par défaut
    idErpCompanies: 0,
    company: { id: 0, name: '', ceoName: '' },
  });

  const [selectedAppId, setSelectedAppId] = useState<number>(1); // ID d'application par défaut
  const { appModules, loading: loadingModules, error: errorModules } = useAppModules();
  const { moduleFeatures, loading: loadingFeatures, error: errorFeatures } = useModuleFeatures();
  const [filteredModules, setFilteredModules] = useState<AppModule[]>([]);
  const [modulePermissions, setModulePermissions] = useState<{ [key: number]: { read: boolean; create: boolean; update: boolean; delete: boolean } }>({});
  const [featurePermissions, setFeaturePermissions] = useState<{ [key: number]: boolean }>({});
  const [useDefaultRights, setUseDefaultRights] = useState<boolean>(true); // Utilisation des droits par défaut activée

  // Permissions par défaut
  const defaultPermissions = {
    1: { read: true, create: false, update: true, delete: false }, // Exemples de permissions par défaut pour le module 1
    2: { read: true, create: true, update: false, delete: false }, // Exemples de permissions par défaut pour le module 2
  };

  // Filtrage des modules selon l'application sélectionnée
  useEffect(() => {
    if (appModules && appModules.length > 0) {
      setFilteredModules(appModules.filter(module => module.idApps === selectedAppId));
    }
  }, [selectedAppId, appModules]);

  const handleModulePermissionChange = (moduleId: number, permission: 'read' | 'create' | 'update' | 'delete') => (e: ChangeEvent<HTMLInputElement>) => {
    setModulePermissions(prevPermissions => ({
      ...prevPermissions,
      [moduleId]: {
        ...prevPermissions[moduleId],
        [permission]: e.target.checked,
      },
    }));
  };

  const handleFeaturePermissionChange = (featureId: number) => (e: ChangeEvent<HTMLInputElement>) => {
    setFeaturePermissions(prevPermissions => ({
      ...prevPermissions,
      [featureId]: e.target.checked,
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  // Gestion du toggle pour l'utilisation des droits par défaut
  const handleUseDefaultRightsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUseDefaultRights(e.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = localStorage.getItem('token'); // Récupération du token
    if (!token) {
      console.error('Token not found');
      return;
    }

    // Si "Utiliser les droits par défaut" est activé, on applique les permissions par défaut
    const finalModulePermissions = useDefaultRights ? defaultPermissions : modulePermissions;

    try {
      const userWithPermissions = {
        ...newUser,
        modulePermissions: finalModulePermissions, // Utilisation des permissions choisies
        featurePermissions, // Inclure les permissions des fonctionnalités
      };

      const response = await fetch('https://rct-backend.ipercom.io/api/User', {
        method: 'POST',
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Envoi du token dans l'en-tête Authorization
        },
        body: JSON.stringify(userWithPermissions),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: UserData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un utilisateur", error);
    }
  };

  // Vérification si les modules et fonctionnalités sont chargés
  if (loadingModules || loadingFeatures) {
    return <p>Chargement...</p>;
  }

  if (errorModules || errorFeatures) {
    return <p>Erreur de chargement des données.</p>;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Form.Group controlId="firstname">
            <Form.Label>Prénom</Form.Label>
            <Form.Control type="text" name="firstname" value={newUser.firstname} onChange={handleChange} required />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastname">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" name="lastname" value={newUser.lastname} onChange={handleChange} required />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group controlId="mail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="mail" value={newUser.mail} onChange={handleChange} required />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="phone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control type="text" name="phone" value={newUser.phone} onChange={handleChange} required />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control type="text" name="mobile" value={newUser.mobile} onChange={handleChange} required />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="company">
            <Form.Label>Société</Form.Label>
            <Form.Control
              type="text"
              name="company"
              value={newUser.company.name}
              onChange={e =>
                setNewUser({
                  ...newUser,
                  company: { ...newUser.company, name: e.target.value },
                } as UserData)
              }
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Toggle pour gestion des droits */}
      <Form.Group controlId="useDefaultRights" className="mt-3">
        <Form.Check type="checkbox" label="Utiliser les droits par défaut" checked={useDefaultRights} onChange={handleUseDefaultRightsChange} />
      </Form.Group>

      {!useDefaultRights && selectedAppId !== null && (
        <Row>
          <h4 className="pt-3 pb-3">Gestion avancée des droits</h4>
          {filteredModules.length > 0 ? (
            filteredModules.map(module => (
              <Col md={12} key={module.idModule}>
                <h5 className="border-bottom pb-2">{module.name}</h5>
                <div style={{ paddingLeft: '20px' }}>
                  <Form.Check
                    type="checkbox"
                    label="Lire"
                    checked={modulePermissions[module.idModule]?.read || false}
                    onChange={handleModulePermissionChange(module.idModule, 'read')}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Créer"
                    checked={modulePermissions[module.idModule]?.create || false}
                    onChange={handleModulePermissionChange(module.idModule, 'create')}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Modifier"
                    checked={modulePermissions[module.idModule]?.update || false}
                    onChange={handleModulePermissionChange(module.idModule, 'update')}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Supprimer"
                    checked={modulePermissions[module.idModule]?.delete || false}
                    onChange={handleModulePermissionChange(module.idModule, 'delete')}
                  />
                  {moduleFeatures.filter(feature => feature.idModule === module.idModule).length > 0 && (
                    <div style={{ paddingLeft: '40px' }}>
                      <h6>Fonctionnalités</h6>
                      {moduleFeatures
                        .filter(feature => feature.idModule === module.idModule)
                        .map(feature => (
                          <Form.Check
                            key={feature.idFeature}
                            type="checkbox"
                            label={feature.name}
                            checked={featurePermissions[feature.idFeature] || false}
                            onChange={handleFeaturePermissionChange(feature.idFeature)}
                          />
                        ))}
                    </div>
                  )}
                </div>
              </Col>
            ))
          ) : (
            <p>Aucun module disponible.</p>
          )}
        </Row>
      )}

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddUserForm;
