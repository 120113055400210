import { useState, useCallback, useEffect } from "react";

export interface MsgVocalData {
  id: number;
  idClient: number;
  name: string;
  url: string;
  date: string;
  state: boolean;
}

// Fallback voicemail data in case the API call fails
const fallBackMsgVocalTableData: MsgVocalData[] = [
  {
    id: 1,
    idClient: 9,
    name: "Client Support",
    url: "http://example.com/support-voicemail.mp3",
    date: "2024-07-17T06:44:25",
    state: true
  },
  {
    id: 2,
    idClient: 10,
    name: "Sales Inquiry",
    url: "http://example.com/sales-voicemail.mp3",
    date: "2024-07-18T09:22:11",
    state: true
  }
];

// Default empty data
const msgVocalTableData: MsgVocalData[] = [];

/**
 * **Messagerie Vocale**:
 * A custom hook that manages voicemail message data, loading state, and error state.
 * It fetches voicemail data from an external API and handles the token from localStorage for authentication.
 *
 * @returns {Object} - The voicemail data, loading state, error message, and a setter for voicemail data.
 *
 * @property {MsgVocalData[]} msgVocalData - Voicemail message data fetched from the API or fallback.
 * @property {Function} setMsgVocalData - Function to manually set voicemail data.
 * @property {boolean} loading - Indicates whether data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 */
const useMsgVocal = () => {
  const [msgVocalData, setMsgVocalData] = useState<MsgVocalData[]>(msgVocalTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/msgvocal', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMsgVocalData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setMsgVocalData(fallBackMsgVocalTableData); // Fallback to data if API call fails
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    msgVocalData,
    setMsgVocalData,
    loading,
    error
  };
};

export default useMsgVocal;
