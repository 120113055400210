import React from 'react';
import { ProgressBar } from 'react-bootstrap';

interface RetailerRanksProgressBarProps {
  current: number;
  target: number;
}

const RetailerRanksProgressBar: React.FC<RetailerRanksProgressBarProps> = ({
  current,
  target
}) => {
  // Calculate the overall progress percentage based on current and target
  const progress = (current / target) * 100;

  return (
    <div className="w-100">
      <div className="d-flex flex-start">
        <div style={{ width: '20.72%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">IVOIRE</p>
        </div>
        <div style={{ width: '35.76%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">SILVER</p>
        </div>
        <div style={{ width: '25.38%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">GOLD</p>
        </div>
        <div style={{ width: '25.14%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">PLATINIUM</p>
        </div>
      </div>

      {/* ProgressBar dynamically adjusts based on calculated progress */}
      <ProgressBar className="mb-3 rounded-3" style={{ height: '10px' }}>
        <ProgressBar
          className="bg-warning border-end border-white border-2"
          now={Math.min(progress, 20.72)} // Bound to 20.72% max
          key={1}
        />
        <ProgressBar
          className="bg-success border-end border-white border-2"
          now={Math.min(progress - 20.72, 35.76)} // Next tier
          key={2}
        />
        <ProgressBar
          className="bg-success border-end border-white border-2"
          now={Math.min(progress - 20.72 - 35.76, 25.38)} // Next tier
          key={3}
        />
        <ProgressBar
          className="bg-white border border-gray border-1"
          now={Math.min(progress - 20.72 - 35.76 - 25.38, 25.14)} // Final tier
          key={4}
        />
      </ProgressBar>

      {/* Display progress details */}
      <p className="text-muted">{`Actuel : ${current} / Objectif : ${target} (${progress.toFixed(2)}%)`}</p>
    </div>
  );
};

export default RetailerRanksProgressBar;
