import useFinalClient from "hooks/api/ipbx/useFinalClient";
import useMsgVocal from "hooks/api/ipbx/useMsgVocal";
import { QueueData } from "hooks/api/ipbx/useQueue";
import React, { ChangeEvent, useState } from "react";
import { Button, Form } from "react-bootstrap";

type QueueFormProps = {
  tableData: QueueData[];
  setTableData: React.Dispatch<React.SetStateAction<QueueData[]>>;
  onClose: () => void;
};

const AddQueueForm: React.FC<QueueFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newQueue, setNewQueue] = useState<QueueData>({
    id: 0,
    idClient: 0,
    name: "",
    timeout: 0,
    strategy: "",
    state: true,
    periodicAnnouce: "",
    periodicAnnouceFrequency: "",
    postNumber: "",
    membername: "",
    ringinuse: true,
  });
  const { clientData, loading: clientLoading } = useFinalClient();
  const { msgVocalData, loading: msgLoading } = useMsgVocal();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewQueue(prevQueue => ({
      ...prevQueue,
      [name]: name === 'idClient' || name === 'timeout' ? Number(value) : value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/queue', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newQueue)
      });

      if (response.ok) {
        const data: QueueData = await response.json();
        setTableData([...tableData, data]);
        onClose();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout d'une file d'attente", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* Nom de la file d'attente */}
      <Form.Group controlId="name">
        <Form.Label>Nom de la F.A</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newQueue.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Numéro de la file d'attente */}
      <Form.Group controlId="postNumber">
        <Form.Label>Numéro de la F.A</Form.Label>
        <Form.Control
          type="text"
          name="postNumber"
          value={newQueue.postNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Timeout */}
      <Form.Group controlId="timeout">
        <Form.Label>Timeout (s)</Form.Label>
        <Form.Control
          type="number"
          name="timeout"
          value={newQueue.timeout}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Stratégie */}
      <Form.Group controlId="strategy">
        <Form.Label>Stratégie</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="strategy"
          value={newQueue.strategy}
          onChange={handleChange}
          required
        >
          <option value="ringall">Sonner tous</option>
          <option value="leastrecent">Le moins récent</option>
          <option value="fewestcalls">Le moins d'appels</option>
          <option value="random">Aléatoire</option>
          <option value="rrmemory">Mémoire</option>
          <option value="linear">Linéaire</option>
          <option value="wrandom">Aléatoire pondéré</option>
          <option value="rrordered">Ordonné</option>
        </Form.Control>
      </Form.Group>

      {/* Membres */}
      <Form.Group controlId="membername">
        <Form.Label>Membres</Form.Label>
        <Form.Control
          type="text"
          name="membername"
          value={newQueue.membername}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Musique d'attente */}
      <Form.Group controlId="periodicAnnouce">
        <Form.Label>Musique d'attente</Form.Label>
        <Form.Control
          as="select"
          name="periodicAnnouce"
          className="form-select"
          value={newQueue.periodicAnnouce}
          onChange={handleChange}
          required
          disabled={msgLoading}
        >
          <option value="">Sélectionnez une musique d'attente</option>
          {msgVocalData.map((msgVocal) => (
            <option key={msgVocal.id} value={msgVocal.name}>
              {msgVocal.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* État */}
      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newQueue.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewQueue({
              ...newQueue,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddQueueForm;
