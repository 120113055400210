import { useState, useCallback, useEffect } from "react";

export interface VoicemailData {
  id: number;
  idClient: number;
  nameVoicemail: string;
  emailUser: string;
  password: string;
  voicemailNumber: number;
  state: boolean;
}

// Fallback data in case API fails
const fallBackVoicemailTableData: VoicemailData[] = [
  {
    id: 1,
    idClient: 9,
    voicemailNumber: 2,
    emailUser: "jhon@cocombre.fr",
    password: "27l96e4!",
    nameVoicemail: "75888",
    state: true
  },
];

// Default empty voicemail data
const voicemailTableData: VoicemailData[] = [];


/**
 * **Répondeur numérique**:
 * A custom hook that manages voicemail data, including loading and error states.
 * It fetches voicemail data from an external API, using token-based authentication stored in localStorage.
 *
 * @returns {Object} - The voicemail data, loading state, error message, and a setter for voicemail data.
 *
 * @property {VoicemailData[]} voicemailData - Voicemail data fetched from the API or fallback data in case of errors.
 * @property {Function} setVoicemailData - Function to manually set the voicemail data.
 * @property {boolean} loading - Indicates whether the voicemail data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * // Example usage of useVoicemail in a component
 * const { voicemailData, loading, error } = useVoicemail();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <VoicemailList data={voicemailData} />;
 */
const useVoicemail = () => {
  const [voicemailData, setVoicemailData] = useState<VoicemailData[]>(voicemailTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/voicemail', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setVoicemailData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // setVoicemailData(fallBackVoicemailTableData); // Use fallback data in case of error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    voicemailData,
    setVoicemailData,
    loading,
    error
  };
};

export default useVoicemail;
