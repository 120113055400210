import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import useMsgVocal from "hooks/api/ipbx/useMsgVocal";
import { QueueData } from "hooks/api/ipbx/useQueue";
import React, { useState } from "react";
import { Dropdown, Button, Modal, Form } from "react-bootstrap";

export const queueAPITableColumns: ColumnDef<QueueData>[] = [
  {
    accessorKey: "name",
    header: "Nom de la F.A",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "postNumber",
    header: "Numéro de la F.A",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorFn: (row) => row.idClient,
    id: 'idClient',
    header: 'Client',
    cell: ({ row: { original } }) => {
      const clientData = useClientData();
      const { idClient } = original;
      const client = clientData.find(client => client.id === idClient);
      return client ? client.name : "Client non trouvé";
    },
    meta: {
      headerProps: { style: { width: '12%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: "timeout",
    header: "Timeout(s)",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "strategy",
    header: "Stratégie",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "agent",
    header: "Membres",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "musique_attente",
    header: "Musique d'attente",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "État",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedQueue, setSelectedQueue] = useState<QueueData | null>(null);
      const [formData, setFormData] = useState<QueueData | null>(null);
      const { msgVocalData, setMsgVocalData, loading, error} = useMsgVocal();

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (queue: QueueData) => {
        setSelectedQueue(queue);
        setShowDeleteModal(true);
      };

      const handleEditClick = (queue: QueueData) => {
        setSelectedQueue(queue);
        setFormData(queue);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedQueue) {
          await handleDelete(selectedQueue.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {

          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/queue/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
          'Authorization': `Bearer ${token}`
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de la file d'attente", error);
        }
      };

      const handleEdit = async (data: QueueData) => {
        try {
          const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
          const { id, ...dataWithoutId } = data;
          const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/queue', {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour de la file d'attente", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>
          <RevealDropdownTrigger
            // show={dropdownOpen}
            // onToggle={toggle}
            // style={{ display: 'flex', justifyContent: 'center' }}
          >
            <RevealDropdown
              // as="div"
              // onClick={toggle}
              // style={{ cursor: 'pointer' }}
              className="fs-9"
            >
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cette file d'attente ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier la file d'attente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="name">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formData?.name || ""}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="postNumber">
        <Form.Label>Numéro</Form.Label>
        <Form.Control
          type="text"
          name="postNumber"
          value={formData?.postNumber || ""}          
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="timeout">
        <Form.Label>Timeout(s)</Form.Label>
        <Form.Control
          type="number"
          name="timeout"
          value={formData?.timeout || ""}          
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="strategy">
        <Form.Label>Stratégie</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="strategy"
          value={formData?.strategy || ""}          
          onChange={handleChange}
          required
        >
          <option value="ringall">Sonner tous</option>
          <option value="leastrecent">Le moins récent</option>
          <option value="fewestcalls">Le moins d'appels</option>
          <option value="random">Aléatoire</option>
          <option value="rrmemory">Mémoire</option>
          <option value="linear">Linéaire</option>
          <option value="wrandom">Aléatoire pondéré</option>
          <option value="rrordered">Ordonné</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="membername">
        <Form.Label>Membres</Form.Label>
        <Form.Control
          type="text"
          name="membername"
          value={formData?.membername || ""}          
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="periodicAnnouce">
        <Form.Label>Musique d'attente</Form.Label>
        <Form.Control
          as="select"
          name="periodicAnnouce"
          className="form-select"
          value={formData?.periodicAnnouce || ""}
          onChange={handleChange}
          required
          disabled={loading}
        >
          <option value="">Sélectionnez une musique d'attente</option>
          {msgVocalData.map((msgVocal) => (
            <option key={msgVocal.id} value={msgVocal.name}>
              {msgVocal.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={formData?.state || false} onChange={handleChange}
        />
      </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const QueueTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default QueueTableAPI;
