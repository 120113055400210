import { useCallback, useEffect, useState } from "react";

export interface PhoneUserData {
  id: number;
  idClient: number;
  idPhoneConfig: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phonePassword: string;
  phoneUsername: string;
  avatar: string;
  extension: string;
  mobile: string;
  fixe: string;
  callStatus: string;
  userStatus: string;
  userFunction: string;
  displayNumber: string;
  displayName: string;
  postParam: number;
  lastActivity?: string;
}

const phoneUserTableData: PhoneUserData[] = [
];

const fallBackPhoneUserTableData: PhoneUserData[] = [
  {
    id: 1,
    idClient: 1,
    idPhoneConfig: 101,
    firstname: "Alice",
    lastname: "Smith",
    email: "alice.smith@example.com",
    password: 'test',
    phonePassword: "alicePhonePass",
    phoneUsername: "alicePhonePass",
    avatar: "https://example.com/avatar1.jpg",
    extension: "1001",
    mobile: "5551234567",
    fixe: "5559876543",
    callStatus: "busy",
    userStatus: 'disponible',
    userFunction: 'disponible',
    displayNumber: 'disponible',
    displayName: 'disponible',
    postParam: 1,
    lastActivity: "1",
  }
];

/**
 * **Utilisateurs de Téléphone**:
 * A custom hook that manages phone user data, including loading state and error state.
 * It fetches phone user data from an external API and handles authentication via a token stored in localStorage.
 *
 * @returns {Object} - The phone user data, loading state, error message, and a setter for phone user data.
 *
 * @property {PhoneUserData[]} phoneUserData - Phone user data fetched from the API or fallback.
 * @property {Function} setPhoneUserData - Function to manually set the phone user data.
 * @property {boolean} loading - Indicates whether the data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * // Example usage of usePhoneUser in a component
 * const { phoneUserData, loading, error } = usePhoneUser();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <PhoneUserList data={phoneUserData} />;
 */

const usePhoneUser = () => {
  const [phoneUserData, setPhoneUserData] = useState<PhoneUserData[]>(phoneUserTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPhoneUsers = useCallback(async () => {
    try {
      // Get token from localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      // Fetch data with authorization token
      const response = await fetch('https://rct-backend.ipercom.io/api/Webrtc/phoneuser', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPhoneUserData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // setPhoneUserData(fallBackPhoneUserTableData); 
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPhoneUsers();
  }, [fetchPhoneUsers]);

  return {
    phoneUserData,
    setPhoneUserData,
    loading,
    error
  };
};

export default usePhoneUser;
