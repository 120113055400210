import { useState, useCallback, useEffect } from "react";
import { ServerData } from "./useServer";

const useServerByID = (serverId: number) => {
  const [serverByIDData, setServerByIDData] = useState<ServerData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log(`Fetching server data for ID: ${serverId}...`);
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/server/${serverId}`, {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: ServerData = await response.json();
      setServerByIDData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données du serveur par ID de l'API", error);
      setError('Impossible de récupérer les données du serveur.');
      setServerByIDData(null); // Reset server data in case of error
    } finally {
      setLoading(false);
    }
  }, [serverId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    serverByIDData,
    loading,
    error
  };
};

export default useServerByID;
