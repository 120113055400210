import { useState, useEffect, useCallback } from 'react';

export interface PhoneConfigData {
  id: number;
  hostSip: string;
  portSip: number;
  hostWebrtc: string;
  portWebrtc: number;
  displayNumber: string;
}

const phoneConfigTableData: PhoneConfigData[] = [
  // Exemples de données initiales
  // { id: 1, host_sip: 'ipbx1.ipercom.io', port_sip: 5060, host_webrtc: 'ipbx1.ipercom.io', port_webrtc: 8089, display_number: '33123456789' },
];

const fallBackPhoneConfigTableData: PhoneConfigData[] = [
  {
    id: 1,
    hostSip: 'fallback.ipercom.io',
    portSip: 5060,
    hostWebrtc: 'fallback.ipercom.io',
    portWebrtc: 8089,
    displayNumber: '33000000000',
  }
];

/**
 * **usePhoneConfig**:
 * Un hook personnalisé pour gérer les données de configuration de téléphone.
 * Il gère également les états de chargement et d'erreur et récupère les données depuis une API externe.
 *
 * @returns {Object} - Les données de configuration de téléphone, l'état de chargement, les erreurs et un setter pour les données.
 *
 * @property {PhoneConfigData[]} phoneConfigData - Les données récupérées depuis l'API ou une valeur fallback.
 * @property {Function} setPhoneConfigData - Fonction pour définir manuellement les données de configuration de téléphone.
 * @property {boolean} loading - Indique si les données sont en cours de chargement.
 * @property {string | null} error - Le message d'erreur, ou null si aucune erreur.
 *
 * @example
 * const { phoneConfigData, loading, error } = usePhoneConfig();
 * if (loading) {
 *   return <p>Chargement...</p>;
 * }
 * if (error) {
 *   return <p>Erreur: {error}</p>;
 * }
 * return <PhoneConfigList data={phoneConfigData} />;
 */

const usePhoneConfig = () => {
  const [phoneConfigData, setPhoneConfigData] = useState<PhoneConfigData[]>(phoneConfigTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPhoneConfigs = useCallback(async () => {
    try {
      // Récupérer le token de localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token non trouvé');
      }

      // Récupérer les données avec le token d'authentification
      const response = await fetch( 'https://rct-backend.ipercom.io/api/Webrtc/phoneconfiguration', {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}` // Ajouter le token aux headers
        },
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      const data = await response.json();
      setPhoneConfigData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setPhoneConfigData(fallBackPhoneConfigTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPhoneConfigs();
  }, [fetchPhoneConfigs]);

  return {
    phoneConfigData,
    setPhoneConfigData,
    loading,
    error
  };
};

export default usePhoneConfig;
