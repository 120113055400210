import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { PhoneUserData } from "hooks/api/ipbx/usePhoneUser";
import usePhoneConfig from "hooks/api/ipbx/usePhoneConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

type PhoneUserFormProps = {
  tableData: PhoneUserData[];
  setTableData: React.Dispatch<React.SetStateAction<PhoneUserData[]>>;
  onClose: () => void;
};

const AddPhoneUserForm: React.FC<PhoneUserFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newUser, setNewUser] = useState<PhoneUserData>({
    id: 0,
    idClient: 0, // We'll update this with client_final_id from localStorage
    idPhoneConfig: 3,
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phonePassword: "",
    phoneUsername: "",
    avatar: "",
    extension: "",
    mobile: "",
    fixe: "",
    callStatus: "",
    userStatus: '',
    userFunction: '',
    displayNumber: '',
    displayName: '',
    postParam: 5
    // lastActivity: null,
  });

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPhonePassword, setShowPhonePassword] = useState(false);

  const { phoneConfigData, loading: phoneConfigLoading, error: phoneConfigError } = usePhoneConfig();

  // Retrieve client_final_id from localStorage
  useEffect(() => {
    const storedIpbxAccess = localStorage.getItem("ipbx_access");

    if (storedIpbxAccess) {
      const ipbxAccess = JSON.parse(storedIpbxAccess);
      if (ipbxAccess.length > 0) {
        const clientFinalId = ipbxAccess[0].client_final_id;
        setNewUser(prevUser => ({
          ...prevUser,
          idClient: clientFinalId  // Set idClient from localStorage
        }));
      }
    }
  }, []);  // Runs once when the component mounts

  const toggleShowPassword = () => {
    setShowPassword(prevShow => !prevShow);
  };

  const toggleShowPhonePassword = () => {
    setShowPhonePassword(prevShow => !prevShow);
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        'Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre majuscule, une lettre minuscule, et un caractère spécial.'
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const parsedValue = (name === 'idClient' || name === 'idPhoneConfig') ? parseInt(value, 10) : value;

    setNewUser(prevUser => ({
      ...prevUser,
      [name]: parsedValue
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (passwordError) {
      return;
    }
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }

    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Webrtc/phoneuser', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newUser)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: PhoneUserData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="firstname">
        <Form.Label>Prénom</Form.Label>
        <Form.Control
          type="text"
          name="firstname"
          value={newUser.firstname}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="lastname">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="lastname"
          value={newUser.lastname}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={newUser.email}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Mot de passe</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? "text" : "password"}
            name="password"
            value={newUser.password}
            onChange={handleChange}
            isInvalid={!!passwordError}
            required
          />
          <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback type="invalid">
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="phonePassword">
        <Form.Label>Mot de passe (Téléphone)</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPhonePassword ? "text" : "password"}
            name="phonePassword"
            value={newUser.phonePassword}
            onChange={handleChange}
            required
          />
          <InputGroup.Text onClick={toggleShowPhonePassword} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={showPhonePassword ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group controlId="avatar">
        <Form.Label>Avatar</Form.Label>
        <Form.Control
          type="text"
          name="avatar"
          value={newUser.avatar}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="extension">
        <Form.Label>Poste interne</Form.Label>
        <Form.Control
          type="text"
          name="extension"
          value={newUser.extension}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="mobile">
        <Form.Label>Mobile</Form.Label>
        <Form.Control
          type="text"
          name="mobile"
          value={newUser.mobile}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="fixe">
        <Form.Label>Fixe</Form.Label>
        <Form.Control
          type="text"
          name="fixe"
          value={newUser.fixe}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="userStatus">
        <Form.Label>Statut Utilisateur</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="userStatus"
          value={newUser.userStatus}
          onChange={handleChange}
          required
        >
          <option value="">Sélectionner le statut utilisateur</option>
          <option value="actif">Actif</option>
          <option value="absent">Absent</option>
          <option value="dnd">Ne pas déranger</option>
          <option value="occupe">Occupé</option>
          <option value="communication_trip">En communication</option>
          <option value="conference_trip">En conférence</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="userFunction">
        <Form.Label>Fonction</Form.Label>
        <Form.Control
          type="text"
          name="userFunction"
          value={newUser.userFunction}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="displayNumber">
        <Form.Label>N° Affiché</Form.Label>
        <Form.Control
          type="text"
          name="displayNumber"
          value={newUser.displayNumber}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="displayName">
        <Form.Label>Nom Affiché</Form.Label>
        <Form.Control
          type="text"
          name="displayName"
          value={newUser.displayName}
          onChange={handleChange}
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddPhoneUserForm;
