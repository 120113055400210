import { useState, useCallback, useEffect } from "react";

export interface RoutingData {
  id: number;
  idClient: number;
  nom: string;
  prefixe: string;
  schedule: string;
  trunk: string;
  action: string;
  displayNumber: string;
  state: boolean;
}

// Fallback data in case API fails
const fallBackRoutingTableData: RoutingData[] = [
  {
    id: 1,
    idClient: 1,
    nom: 'sortante',
    prefixe: "6547",
    schedule: "Repos",
    trunk: "Zoe",
    action: "Dormir",
    displayNumber: "258963147859",
    state: false
  },
];

// Default empty data
const routingTableData: RoutingData[] = [];


/**
 * **Règles sortantes**:
 * A custom hook that manages outbound routing rules data, including loading and error states.
 * It fetches routing data (prefixes, schedules, trunks, actions) from an external API and handles authentication via a token stored in localStorage.
 *
 * @returns {Object} - The routing data, loading state, error message, and setter for routing data.
 *
 * @property {RoutingData[]} routingData - Routing data fetched from the API or fallback data in case of errors.
 * @property {Function} setRoutingData - Function to manually set the routing data.
 * @property {boolean} loading - Indicates whether data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * // Example usage of useRouting in a component
 * const { routingData, loading, error } = useRouting();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <RoutingList data={routingData} />;
 */
const useRouting = () => {
  const [routingData, setRoutingData] = useState<RoutingData[]>(routingTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/routing', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setRoutingData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setRoutingData(fallBackRoutingTableData); // Use fallback data in case of error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    routingData,
    setRoutingData,
    loading,
    error
  };
};

export default useRouting;
