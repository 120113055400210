import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import PDF viewer styles
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

interface Order {
  id: string;
  ref: string;
  date: string;
  total_ht: string;
  total_tva: string;
  total_ttc: string;
  socid: string;
  lines: OrderLine[];
}

interface OrderLine {
  id: string;
  product_ref: string;
  product_label: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  total_ht: string;
  total_ttc: string;
  array_options: {
    options_licences?: string[];
  };
}

const formatPrice = (price: string) => {
  const parsedPrice = parseFloat(price);
  return isNaN(parsedPrice) ? "0.00" : parsedPrice.toFixed(2);
};

const OrderDetails: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [pdfGenerated, setPdfGenerated] = useState(false); // To avoid infinite generation
  const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const isNumeric = /^\d+$/.test(orderId ?? "");

        const endpoint = isNumeric
          ? `https://erp.ipercom.io/api/index.php/orders/${orderId}`
          : `https://erp.ipercom.io/api/index.php/orders/ref/${orderId}?contact_list=1`;

        const response = await fetch(endpoint, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || ""
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order details.');
        }

        const data = await response.json();
        setOrder(data);
      } catch (error) {
        setError('Failed to load order details.');
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  useEffect(() => {
    // Automatically generate PDF if it doesn't exist when the order is loaded
    if (order && !pdfData && !pdfGenerated) {
      generateDocument();
    }
  }, [order, pdfData, pdfGenerated]);

  const generateDocument = async () => {
    try {
      const documentEndpoint =
        "https://erp.ipercom.io/api/index.php/documents/builddoc";
      const body = {
        modulepart: 'order',
        original_file: `${order?.ref}/${order?.ref}.pdf`,
        doctemplate: 'eratosthene',
        langcode: "fr_FR"
      };

      const response = await fetch(documentEndpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          DOLAPIKEY: DOLAPIKEY || ""
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error('Failed to generate document.');
      }

      const fileResponse = await fetch(
        `https://erp.ipercom.io/api/index.php/documents/download?modulepart=order&original_file=${order?.ref}%2F${order?.ref}.pdf`,
        {
          headers: {
            DOLAPIKEY: DOLAPIKEY || "",
            Accept: "application/json"
          }
        }
      );

      if (!fileResponse.ok) {
        throw new Error('Failed to download the document.');
      }

      const fileData = await fileResponse.json();
      setPdfData(fileData.content);
      setPdfGenerated(true); // Set flag to true to avoid repeated generation
    } catch (error) {
      setError("Failed to generate or download the document.");
    }
  };

  const downloadPDF = () => {
    if (pdfData) {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdfData}`;
      link.download = `${order?.ref}.pdf`;
      link.click();
    }
  };

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!order) {
    return <div>Loading order details...</div>;
  }

  return (
    <Container className="my-3">
      <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
        ← Retour
      </Button>

      <Row>
        {/* PDF Viewer Column */}
        <Col lg={6}>
          <Card>
            <Card.Header>
              <h5>Document PDF</h5>
            </Card.Header>
            <Card.Body>
              {pdfData ? (
                <div style={{ width: "100%" }}>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}
                  >
                    <Viewer
                      fileUrl={`data:application/pdf;base64,${pdfData}`}
                    />
                  </Worker>
                </div>
              ) : (
                <Alert variant="info">Generating PDF document...</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Order Info and Controls Column */}
        <Col lg={6}>
          <Card>
            <Card.Header>
              <h4>Détails de la commande - {order.ref}</h4>
              <p>Date: {new Date(order.date).toLocaleDateString()}</p>
            </Card.Header>
            <Card.Body>
              <h5>Informations sur la commande</h5>
              <p>
                <strong>Total HT:</strong> {formatPrice(order.total_ht)} EUR
              </p>
              <p>
                <strong>Total TVA:</strong> {formatPrice(order.total_tva)} EUR
              </p>
              <p>
                <strong>Total TTC:</strong> {formatPrice(order.total_ttc)} EUR
              </p>

              <h5>Informations client</h5>
              <p>
                <strong>Numéro client:</strong> {order.socid}
              </p>

              <Button
                variant="primary"
                onClick={generateDocument}
                className="mt-3 me-1"
              >
                Générer le récapitulatif de la commande
              </Button>
              {pdfData && (
                <Button
                  variant="success"
                  className="mt-3 ml-3"
                  onClick={downloadPDF}
                >
                  Télécharger le PDF
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Order Lines Table */}
      <Card className="mt-3">
        <Card.Header>
          <h5>Lignes de commande</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Product Reference</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price HT</th>
              <th>TVA (%)</th>
              <th>Total HT</th>
              <th>Total TTC</th>
              <th>Licenses</th>
            </tr>
            </thead>
            <tbody>
            {order.lines.map(line => (
              <tr key={line.id}>
                <td>{line.product_ref}</td>
                <td>{line.product_label}</td>
                <td>{line.qty}</td>
                <td>{formatPrice(line.subprice)} EUR</td>
                <td>{line.tva_tx}</td>
                <td>{formatPrice(line.total_ht)} EUR</td>
                <td>{formatPrice(line.total_ttc)} EUR</td>
                <td>
                  {line.array_options?.options_licences
                    ? String(line.array_options.options_licences)
                    : "No additional options available"}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OrderDetails;
