import { useState, useCallback, useEffect } from "react";

export interface QueueData {
  id: number ,
  idClient: number,
  name: string,
  timeout: number,
  strategy: string,
  state: boolean,
  periodicAnnouce: string,
  periodicAnnouceFrequency: string,
  postNumber: string,
  membername: string,
  ringinuse: boolean,
}

// Default empty queue data
const queueTableData: QueueData[] = [];

// Fallback professional data in case API fails
const fallBackQueueTableData: QueueData[] = [
  {
    id: 1,
    idClient: 9,
    name: "Support Queue",
    timeout: 120,
    strategy: "ringall",
    state: true,
    periodicAnnouce: "string",
    periodicAnnouceFrequency: "string",
    postNumber: "string",
    membername: "string",
    ringinuse: true
  },
];


/**
 * **Files d'attente** :
 * useQueue is a custom hook that manages the queue data, loading state, and error state.
 * It fetches the queue data from an external API and handles the token from localStorage.
 *
 * @returns {Object} The queue data, loading state, error message, and setter for queue data.
 *
 * @property {QueueData[]} queueData - The queue data fetched from the API or fallback.
 * @property {Function} setQueueData - The function to manually set the queue data.
 * @property {boolean} loading - The loading state indicating whether data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 */
const useQueue = () => {
  const [queueData, setQueueData] = useState<QueueData[]>(queueTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/queue', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setQueueData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // setQueueData(fallBackQueueTableData); // Use fallback data in case of error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    queueData,
    setQueueData,
    loading,
    error
  };
};

export default useQueue;
