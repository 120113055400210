import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Dropdown, Form, Modal, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { ColumnDef } from "@tanstack/react-table";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import RevealDropdown from "components/base/RevealDropdown"; // Updated import
import Badge from "components/base/Badge";
import { Ticket } from "../../../../hooks/dolibarr/useTicketsData";
import AdvanceTable from "../../../../components/base/AdvanceTable";


const isRTL = '';

interface TicketsCellProps {
  name: string;
}

const TicketsCell: React.FC<TicketsCellProps> = ({ name }) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Link
        to={``}
        className="text-decoration-none fw-bold fs-9"
        onClick={handleShowModal}
      >
        {name}
      </Link>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Adjust form fields as per Dolibarr's ticket structure */}
            <Form.Group controlId="formBasicSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control type="text" placeholder="Enter subject" />
            </Form.Group>

            <Form.Group controlId="formBasicStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select">
                <option>Open</option>
                <option>In Progress</option>
                <option>Closed</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicPriority">
              <Form.Label>Priority</Form.Label>
              <Form.Control as="select">
                <option>Low</option>
                <option>Medium</option>
                <option>High</option>
              </Form.Control>
            </Form.Group>

            {/* Add other necessary fields */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const TicketsTableColumns: ColumnDef<Ticket>[] = [
  {
    accessorKey: 'subject',
    header: 'Sujet',
    cell: ({ row }) => (
      <Link to={`/portail-revendeur/vos-tickets/${row.original.id}`}>
        {row.original.subject}
      </Link>
    ),
  },
  {
    accessorKey: 'type',
    header: 'Type',
  },
  {
    accessorKey: 'severity',
    header: 'Sévérité',
  },
  {
    accessorKey: 'author',
    header: 'Auteur',
  },
  // {
  //   accessorKey: 'company',
  //   header: 'Tiers',
  // },
  {
    accessorKey: 'creationDate',
    header: 'Date création',
    cell: ({ getValue }) => {
      const value = getValue<string>();
      return new Date(value).toLocaleDateString();
    },
  },
  {
    accessorKey: 'status.label',
    header: 'État',
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge variant="phoenix" bg="success">
          {status.label}
        </Badge>
      );
    },
  },
  {
    id: 'assigness',
    header: 'Assignés',
    cell: ({ row: { original } }) => {
      const { assigness } = original;
      const [showModal, setShowModal] = useState(false);

      const handleShowModal = () => setShowModal(true);
      const handleCloseModal = () => setShowModal(false);

      // Ensure assigness is defined and an array
      const safeAssigness = Array.isArray(assigness) ? assigness : [];

      return (
        <div className="d-flex align-items-center">
          {safeAssigness.length > 0 ? (
            <Avatar.Group total={safeAssigness.length} size="s">
              {safeAssigness.map((assigne) => (
                assigne && (
                  <Avatar
                    key={assigne.id}
                    src={assigne.avatar ? assigne.avatar : undefined}
                    variant={assigne.avatar ? 'image' : 'name'}
                    size="s"
                  >
                    {!assigne.avatar && assigne.name[0]}
                  </Avatar>
                )
              ))}
            </Avatar.Group>
          ) : (
            <>
              <Button variant="link" onClick={handleShowModal} className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                Assigner
              </Button>
              <Dropdown align={isRTL ? 'end' : 'start'} show={showModal} onToggle={() => setShowModal(!showModal)}>
                <Dropdown.Menu className="shadow-sm" style={{ minWidth: '20rem' }}>
                  <Card className="position-relative border-0">
                    <Card.Body className="p-0">
                      <div className="mx-3">
                        <h4 className="mb-3 fw-bold">Sélection du contact</h4>
                        <Form.Select className="mb-3">
                          <option value="">Sélectionner</option>
                          {safeAssigness.map((assigne) => (
                            <option key={assigne.id} value={assigne.id}>
                              {assigne.name}
                            </option>
                          ))}
                        </Form.Select>
                        <div className="text-end">
                          <Button variant="link" className="text-danger" onClick={handleCloseModal}>
                            Annuler
                          </Button>
                          <Button size="sm" variant="primary" className="px-5" onClick={handleCloseModal}>
                            Sauvegarder
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' },
    },
  },

  {
    accessorKey: 'progress',
    header: 'Progression',
    cell: ({ row: { original } }) => {
      const { progress } = original;

      return progress ? (
        <>
          <p className="text-800 fs-10 mb-0">
            {progress.min} / {progress.max}
          </p>
          <ProgressBar
            now={(progress.min / progress.max) * 100}
            style={{ height: 3 }}
            variant="success"
          />
        </>
      ) : null;
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '5%' }, className: 'ps-3' },
    },
  },
  {
    id: 'actions',
    header: 'Actions',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [formData, setFormData] = useState<Partial<Ticket>>(original);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = () => {
        setShowDeleteModal(true);
      };

      const handleEditClick = () => {
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        const DOLAPIKEY = localStorage.getItem('DOLAPIKEY'); // Ensure you have stored the Dolibarr token
        if (!DOLAPIKEY) {
          alert('Authentication token not found.');
          return;
        }

        try {
          const response = await fetch(
            `https://erp.ipercom.io/api/index.php/tickets/${original.id}`,
            {
              method: 'DELETE',
              headers: {
                'Authorization': `Bearer ${DOLAPIKEY}`,
                'Accept': 'application/json',
              }
            }
          );

          if (response.ok) {
            // Optionally, refresh the table data or remove the deleted row from state
            window.location.reload();
          } else {
            const errorData = await response.json();
            console.error('Error deleting ticket:', errorData);
            alert('There was an error deleting the ticket.');
          }
        } catch (error) {
          console.error('Error deleting ticket:', error);
          alert('An unexpected error occurred.');
        } finally {
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        const DOLAPIKEY = localStorage.getItem('DOLAPIKEY'); // Ensure you have stored the Dolibarr token
        if (!DOLAPIKEY) {
          alert('Authentication token not found.');
          return;
        }

        // Validate formData as per Dolibarr's requirements
        if (!formData.subject || !formData.status) {
          alert('Please fill in all required fields.');
          return;
        }

        try {
          const response = await fetch(
            `https://erp.ipercom.io/api/index.php/tickets/${original.id}`,
            {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${DOLAPIKEY}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
              body: JSON.stringify(formData)
            }
          );

          if (response.ok) {
            // Optionally, refresh the table data or update the row in state
            window.location.reload();
          } else {
            const errorData = await response.json();
            console.error('Error updating ticket:', errorData);
            alert('There was an error updating the ticket.');
          }
        } catch (error) {
          console.error('Error updating ticket:', error);
          alert('An unexpected error occurred.');
        } finally {
          setShowEditModal(false);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

      return (
        <>
          <RevealDropdown
            // show={dropdownOpen}
            // onToggle={toggle}
            // align={isRTL ? 'end' : 'start'}
            className="fs-9"
          >
            <Dropdown.Item onClick={handleEditClick}>
              Modifier
            </Dropdown.Item>
            <Dropdown.Item onClick={handleDeleteClick}>
              Supprimer
            </Dropdown.Item>
          </RevealDropdown>

          {/* Delete Confirmation Modal */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce ticket ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Edit Ticket Modal */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="subject">
                  <Form.Label>Sujet</Form.Label>
                  <Form.Control
                    type="text"
                    name="subject"
                    value={formData.subject || ''}
                    // onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="status">
                  <Form.Label>Statut</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={typeof formData.status === 'string' ? formData.status : formData.status?.label || ''} // Ensure it's a string
                    required
                  >

                  <option value="">Sélectionner le statut</option>
                    <option value="Open">Ouvert</option>
                    <option value="In Progress">En cours</option>
                    <option value="Closed">Fermé</option>
                    {/* Add other statuses as per Dolibarr */}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="priority">
                  <Form.Label>Priorité</Form.Label>
                  <Form.Control
                    as="select"
                    name="priority"
                    value={formData.priority || ''}
                    // onChange={handleChange}
                  >
                    <option value="">Sélectionner la priorité</option>
                    <option value="Low">Faible</option>
                    <option value="Medium">Moyenne</option>
                    <option value="High">Haute</option>
                  </Form.Control>
                </Form.Group>

                {/* Add other fields as necessary based on Dolibarr's ticket structure */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const TicketsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default TicketsTable;
