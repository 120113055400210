import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

interface Invoice {
  ref: string;
  date: string;
  total_ht: string;
  total_tva: string;
  total_ttc: string;
  socid: string;
  status: string;
  lines: InvoiceLine[];
  array_options: {
    options_ref_commande?: string;
  };
}

interface InvoiceLine {
  product_ref: string;
  product_label: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  total_ht: string;
  total_ttc: string;
  array_options: {
    options_licences?: string[];
  };
}

const InvoiceDetails: React.FC = () => {
  const { invoiceRef } = useParams<{ invoiceRef: string }>();
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        const isNumeric = /^\d+$/.test(invoiceRef ?? "");

        const endpoint = isNumeric
          ? `https://erp.ipercom.io/api/index.php/invoices/${invoiceRef}?contact_list=1`
          : `https://erp.ipercom.io/api/index.php/invoices/ref/${invoiceRef}?contact_list=1`;

        const response = await fetch(endpoint, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || ""
          }
        });

        if (!response.ok) {
          throw new Error("Échec de la récupération des détails de la facture.");
        }

        const data = await response.json();
        setInvoice(data);
      } catch (error) {
        setError("Échec du chargement des détails de la facture.");
      }
    };

    fetchInvoiceDetails();
  }, [invoiceRef, DOLAPIKEY]);

  useEffect(() => {
    if (invoice && !pdfData && !pdfGenerated) {
      generateDocument();
    }
  }, [invoice, pdfData, pdfGenerated]);

  const generateDocument = async () => {
    try {
      const documentEndpoint =
        "https://erp.ipercom.io/api/index.php/documents/builddoc";
      const body = {
        modulepart: "invoice",
        original_file: `${invoice?.ref}/${invoice?.ref}.pdf`,
        doctemplate: "sponge",
        langcode: "fr_FR"
      };

      const response = await fetch(documentEndpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          DOLAPIKEY: DOLAPIKEY || ""
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error("Échec de la génération du document.");
      }

      const fileResponse = await fetch(
        `https://erp.ipercom.io/api/index.php/documents/download?modulepart=invoice&original_file=${invoice?.ref}%2F${invoice?.ref}.pdf`,
        {
          headers: {
            DOLAPIKEY: DOLAPIKEY || "",
            Accept: "application/json"
          }
        }
      );

      if (!fileResponse.ok) {
        throw new Error("Échec du téléchargement du document.");
      }

      const fileData = await fileResponse.json();
      setPdfData(fileData.content);
      setPdfGenerated(true);
    } catch (error) {
      setError("Échec de la génération ou du téléchargement du document.");
    }
  };

  const downloadPDF = () => {
    if (pdfData) {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdfData}`;
      link.download = `${invoice?.ref}.pdf`;
      link.click();
    }
  };

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!invoice) {
    return <div>Loading invoice details...</div>;
  }

  return (
    <Container className="mt-5">
      {/* Button for going back */}
      <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
        ← Retour
      </Button>

      <Row>
        {/* PDF Viewer on the left */}
        <Col lg={6}>
          <Card>
            <Card.Header>
              <h5>Facture PDF</h5>
            </Card.Header>
            <Card.Body>
              {pdfData ? (
                <div style={{ width: "100%" }}>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}
                  >
                    <Viewer
                      fileUrl={`data:application/pdf;base64,${pdfData}`}
                    />
                  </Worker>
                </div>
              ) : (
                <Alert variant="info">Generating PDF document...</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Invoice Details and Controls on the right */}
        <Col lg={6}>
          <Card>
            <Card.Header>
              <h4>Détails de la facture - {invoice.ref}</h4>
              <p>Date: {new Date(invoice.date).toLocaleDateString()}</p>
            </Card.Header>
            <Card.Body>
              <h5>Informations sur la facture</h5>
              <p>
                <strong>Total HT:</strong> {invoice.total_ht} EUR
              </p>
              <p>
                <strong>Total TVA:</strong> {invoice.total_tva} EUR
              </p>
              <p>
                <strong>Total TTC:</strong> {invoice.total_ttc} EUR
              </p>
              <p>
                <strong>Référence de commande:</strong>{" "}
                {invoice.array_options?.options_ref_commande || "N/A"}
              </p>

              <h5>Informations client</h5>
              <p>
                <strong>Numéro client:</strong> {invoice.socid}
              </p>
              <p>
                <strong>Statut:</strong>{" "}
                {invoice.status === "1" ? "Non payé" : "Payé"}
              </p>

              {/* Generate and Download PDF Controls */}
              <Button
                variant="primary"
                onClick={generateDocument}
                className="mt-3 me-1"
              >
                Générer la facture PDF
              </Button>
              {pdfData && (
                <Button
                  variant="success"
                  className="mt-3 ml-3"
                  onClick={downloadPDF}
                >
                  Télécharger le PDF
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Invoice Lines Table */}
      <Card className="mt-3">
        <Card.Header>
          <h5>Lignes de facture</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Product Reference</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price HT</th>
              <th>TVA (%)</th>
              <th>Total HT</th>
              <th>Total TTC</th>
              <th>Licenses</th>
            </tr>
            </thead>
            <tbody>
            {invoice.lines.map((line, index) => (
              <tr key={index}>
                <td>{line.product_ref}</td>
                <td>{line.product_label}</td>
                <td>{line.qty}</td>
                <td>{line.subprice} EUR</td>
                <td>{line.tva_tx}</td>
                <td>{line.total_ht} EUR</td>
                <td>{line.total_ttc} EUR</td>
                <td>
                  {line.array_options?.options_licences
                    ? String(line.array_options.options_licences)
                    : "No additional options available"}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InvoiceDetails;
