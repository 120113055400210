import React, { ChangeEvent, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/others/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import usePhoneUser from 'hooks/api/ipbx/usePhoneUser'; 
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import UserPhoneTableAPI, { phoneUserTableColumns } from './UserPhoneTableAPI';
import AddPhoneUserForm from './AddPhoneUserForm';
import useFinalClient from 'hooks/api/ipbx/useFinalClient';
import { ClientProvider } from 'data/iper-x/context/ClientContext';
import usePhoneConfig from 'hooks/api/ipbx/usePhoneConfig';
import { PhoneConfigProvider } from 'data/iper-x/context/PhoneConfigurationContext';


const UserPhoneListAPI = () => {
  const { phoneUserData, setPhoneUserData, loading, error } = usePhoneUser();
  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();
  const { phoneConfigData, loading: phoneConfigLoading, error: phoneConfigError } = usePhoneConfig();
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  // Initialisation du tableau avancé avec les données utilisateur
  const table = useAdvanceTable({ 
    data: phoneUserData,
    columns: phoneUserTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <ClientProvider clientData={clientData}>
      <PhoneConfigProvider phoneConfigData={phoneConfigData}>
      <div>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Liste des utilisateurs</span>
          </h2>
        </div>

        {/* Search and Filter */}
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button
                variant="primary"
                onClick={() => setShowAddClientModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un utilisateur
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>

        {/* Gestion du chargement et des erreurs */}
        {loading && clientLoading && phoneConfigLoading && <p>Chargement des données...</p>}
          {(error || clientError || phoneConfigError) && (
            <p style={{ color: 'red' }}>{error || clientError || phoneConfigError}</p>
          )}
        {/* Contenu du tableau */}
        {!loading && !clientLoading && !phoneConfigLoading && (
          <AdvanceTableProvider {...table}>
            <div className="mx-n4 px-2 mx-lg-n6 bg-white border-top border-bottom border-200 position-relative top-1">
              <UserPhoneTableAPI />
            </div>
          </AdvanceTableProvider>
        )}

        {/* Add Phone User Modal */}
        <Modal
          show={showAddClientModal}
          onHide={() => setShowAddClientModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un utilisateur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPhoneUserForm
              tableData={phoneUserData}
              setTableData={setPhoneUserData}
              onClose={() => setShowAddClientModal(false)}
            />
          </Modal.Body>
        </Modal>
      </div>
      </PhoneConfigProvider>
    </ClientProvider>
  );
};

export default UserPhoneListAPI;
