import {
  Icon,
  UilEnvelope,
  UilEnvelopeBlock,
  UilGraphBar,
  UilLeftArrowFromLeft,
  UilPostcard,
  UilPower,
  UilRefresh,
  UilShutterAlt,
  UilUsersAlt
} from "@iconscout/react-unicons";

export interface StatType {
  id: number;
  icon: Icon;
  iconColor: string;
  emailCount: string;
  title: string;
}
export const stats: StatType[] = [
  {
    id: 1,
    icon: UilPostcard,
    iconColor: 'primary',
    emailCount: '28,00',
    title: 'Total des licences'
  },
  {
    id: 2,
    icon: UilRefresh,
    iconColor: 'info',
    emailCount: '1,866',
    title: 'Licences à jour'
  },
  {
    id: 3,
    icon: UilPower,
    iconColor: 'primary',
    emailCount: '1,366',
    title: 'Mise à jour manquante'
  },
  {
    id: 4,
    icon: UilGraphBar,
    iconColor: 'info',
    emailCount: '1,200',
    title: "Chiffre d'affaire"
  },
  {
    id: 5,
    icon: UilLeftArrowFromLeft,
    iconColor: 'success',
    emailCount: '900',
    title: 'Version ancienne'
  },
  {
    id: 6,
    icon: UilEnvelopeBlock,
    iconColor: 'danger',
    emailCount: '500',
    title: 'Souscriptions en cours d’expiration'
  },
  {
    id: 7,
    icon: UilShutterAlt,
    iconColor: 'info',
    emailCount: '1,200',
    title: 'Objectif à réaliser'
  },
  {
    id: 8,
    icon: UilEnvelope,
    iconColor: 'success',
    emailCount: '900',
    title: 'Nouvelle commande'
  },
  {
    id: 9,
    icon: UilUsersAlt,
    iconColor: 'danger',
    emailCount: '500',
    title: 'Nombre de client'
  },
];



export const dummyStats: StatType[] = [
  {
    id: 1,
    icon: UilPostcard,
    iconColor: 'primary',
    emailCount: '0',
    title: 'Total des licences',
  },
  {
    id: 2,
    icon: UilRefresh,
    iconColor: 'info',
    emailCount: '0',
    title: 'Licences à jour',
  },
  {
    id: 3,
    icon: UilPower,
    iconColor: 'primary',
    emailCount: '0',
    title: 'Mise à jour manquante',
  },
  {
    id: 4,
    icon: UilGraphBar,
    iconColor: 'info',
    emailCount: '0',
    title: "Chiffre d'affaires"
  },
  {
    id: 5,
    icon: UilLeftArrowFromLeft,
    iconColor: 'success',
    emailCount: '0',
    title: 'Version ancienne',
  },
  {
    id: 6,
    icon: UilEnvelopeBlock,
    iconColor: 'danger',
    emailCount: '0',
    title: 'Souscriptions en cours d’expiration',
  },
  {
    id: 7,
    icon: UilShutterAlt,
    iconColor: 'info',
    emailCount: '6000',
    title: 'Objectif à réaliser',
  },
  {
    id: 8,
    icon: UilEnvelope,
    iconColor: 'success',
    emailCount: '0',
    title: 'Nouvelle commande',
  },
  {
    id: 9,
    icon: UilUsersAlt,
    iconColor: 'danger',
    emailCount: '0',
    title: 'Nombre de client',
  },
];