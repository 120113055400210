import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import { VoicemailData } from "hooks/api/ipbx/useVoicemail";
import React, { useState } from "react";
import { Button, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";

const PasswordCell = ({ password }: { password: string }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  return (
    <div className="">
      <span className="me-2">
        {showPassword ? password : "••••••••"}
      </span>
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        onClick={toggleShowPassword}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export const voicemailAPITableColumns: ColumnDef<VoicemailData>[] = [
  {
    accessorKey: "nameVoicemail",
    header: "Nom de la boîte vocale",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorFn: (row) => row.idClient,
    id: 'idClient',
    header: 'Client',
    cell: ({ row: { original } }) => {
      const clientData = useClientData();
      const { idClient } = original;
      const client = clientData.find(client => client.id === idClient);
      return client ? client.name : "Client non trouvé";
    },
    meta: {
      headerProps: { style: { width: '12%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: "emailUser",
    header: "Email",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "password",
    header: "Mot de passe",
    cell: ({ row: { original } }) => (
      <PasswordCell password={original.password} />
    ),
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "voicemailNumber",
    header: "Numéro de la boîte vocale",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "État",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedVoicemail, setSelectedVoicemail] = useState<VoicemailData | null>(null);
      const [formData, setFormData] = useState<VoicemailData | null>(null);
      const [passwordError, setPasswordError] = useState<string | null>(null);
      const [showPassword, setShowPassword] = useState(false)


      const toggleShowPassword = () => {
        setShowPassword((prevShow) => !prevShow);
      };

      const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
        if (!passwordRegex.test(password)) {
          setPasswordError(
            'Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre majuscule, une lettre minuscule, et un caractère spécial.'
          );
        } else {
          setPasswordError(null);
        }
      };


      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (voicemail: VoicemailData) => {
        setSelectedVoicemail(voicemail);
        setShowDeleteModal(true);
      };

      const handleEditClick = (voicemail: VoicemailData) => {
        setSelectedVoicemail(voicemail);
        setFormData(voicemail);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedVoicemail) {
          await handleDelete(selectedVoicemail.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/voicemail/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
          'Authorization': `Bearer ${token}`
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression du répondeur numérique", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value, type, checked } = e.target;

        if (name === 'password' || name === 'phonePassword') {
          validatePassword(value);
        }
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      const handleEdit = async (data: VoicemailData) => {
        if (passwordError) {
          return;
        }
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const { id, ...dataWithoutId } = data;
          const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/voicemail', {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour du répondeur numérique", error);
        }
      };



      return (
        <>
          <RevealDropdownTrigger
            // show={dropdownOpen}
            // onToggle={toggle}
            // style={{ display: 'flex', justifyContent: 'center' }}
          >
            <RevealDropdown
              // as="div"
              // onClick={toggle}
              // style={{ cursor: 'pointer' }}
              className="fs-9"
            >
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce répondeur numérique ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le répondeur numérique</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="nameVoicemail">
                  <Form.Label>Nom de la boîte vocale</Form.Label>
                  <Form.Control
                    type="text"
                    name="nameVoicemail"
                    value={formData?.nameVoicemail || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="emailUser">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="emailUser"
                    value={formData?.emailUser || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Mot de passe (Téléphone)</Form.Label>
                  <InputGroup>
                  <Form.Control
                   type={showPassword ? 'text' : 'password'} 
                    name="phonePassword"
                    value={formData?.password || ''}
                    onChange={handleChange}
                    isInvalid={!!passwordError}
                    required
                  />
                  <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback type="invalid">
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>
                <Form.Group controlId="voicemailNumber">
                  <Form.Label>Numéro de la boîte vocale</Form.Label>
                  <Form.Control
                    type="number"
                    name="voicemailNumber"
                    value={formData?.voicemailNumber || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="state">
                  <Form.Label>État</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="state"
                    checked={formData?.state}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const VoicemailTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default VoicemailTableAPI;
