import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge, { BadgeBg } from "components/base/Badge"; // Import BadgeBg
import { PhoneUserData } from "hooks/api/ipbx/usePhoneUser";
import React, { useState } from "react";
import { formatDistanceToNow, isBefore, subMinutes } from "date-fns";
import Avatar from "components/base/Avatar";
import { fr } from "date-fns/locale";
import { usePhoneConfigData } from "data/iper-x/context/PhoneConfigurationContext";
import { useClientData } from "data/iper-x/context/ClientContext";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PasswordCell = ({ password }: { password: string }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  return (
    <div className="">
      <span className="me-2">
        {showPassword ? password : "••••••••"}
      </span>
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        onClick={toggleShowPassword}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export const phoneTableColumns: ColumnDef<PhoneUserData>[] = [
  {
    accessorFn: ({ lastActivity }) => lastActivity,
    id: 'status',
    header: 'Utilisateur',
    cell: ({ row: { original } }) => {
      const { lastActivity, firstname, lastname, id } = original;

      const isOnline = isBefore(
        subMinutes(new Date(), 5),
        new Date(lastActivity ?? Date.now())
      );

      const avatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(firstname)}+${encodeURIComponent(lastname)}&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center">
          <Avatar
            src={avatar}
            size="m"
            className="me-1"
            status={isOnline ? "online" : "offline"}
          />
          <div className="d-flex flex-column ms-1">
            <p className="mb-0 fw-bold text-dark">
              <a href={`utilisateurs/details/${id}`} className="text-decoration-none text-dark">
                {firstname} {lastname}
              </a>
            </p>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "8%", fontSize: "11px" }
      },
      cellProps: { className: "ps-2" }
    }
  },
  {
    accessorKey: 'extension',
    header: 'Poste interne',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorFn: (row) => row.idClient,
    id: 'idClient',
    header: 'Client',
    cell: ({ row: { original } }) => {
      const clientData = useClientData();
      const { idClient } = original;
      const client = clientData.find(client => client.id === idClient);
      return client ? client.name : "Client non trouvé";
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'phoneUsername',
    header: 'Username SIP',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: "phonePassword",
    header: 'Password SIP',
    cell: ({ row: { original } }) => (
      <PasswordCell password={original.phonePassword} />
    ),
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'lastActivity',
    header: 'Activité',
    cell: ({ getValue }) => {
      const lastActivity = getValue() as string | number;
      const formattedTimeAgo = formatDistanceToNow(new Date(lastActivity), { addSuffix: true, locale: fr });

      return <span>{formattedTimeAgo}</span>;
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'test',
    header: 'Info téléphone',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'callStatus',
    header: 'Statut D’appel',
    cell: ({ row: { original } }) => {
      const { callStatus } = original;
      const statusCallMapping = {
        online: { color: 'success', label: 'En ligne' },
        waiting: { color: 'warning', label: 'En attente' },
        unregistered: { color: 'danger', label: 'Non inscrit' }
      };

      const { color, label } = statusCallMapping[callStatus as keyof typeof statusCallMapping] ?? { color: 'secondary', label: 'Inconnu' };

      return <Badge bg={color as BadgeBg} className="text-uppercase">{label}</Badge>;  
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorFn: (row) => row.idPhoneConfig,
    id: 'idPhoneConfig',
    header: 'Sous domaine instance',
    cell: ({ row: { original } }) => {
      const phoneConfigData = usePhoneConfigData();
      const { idPhoneConfig } = original;
      const phoneConfig = phoneConfigData.find(phoneConfig => phoneConfig.id === idPhoneConfig);
      return phoneConfig ? phoneConfig.hostSip : "Phone config non trouvé";
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  }
];

const PhoneTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default PhoneTableAPI;
