import useFinalClient from "hooks/api/ipbx/useFinalClient";
import { ScheduleData } from "hooks/api/ipbx/useSchedule";
import React, { ChangeEvent, useState } from "react";
import { Button, Form } from "react-bootstrap";

type ScheduleFormProps = {
  tableData: ScheduleData[];
  setTableData: React.Dispatch<React.SetStateAction<ScheduleData[]>>;
  onClose: () => void;
};

const AddScheduleForm: React.FC<ScheduleFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newSchedule, setNewSchedule] = useState<ScheduleData>({
    id: 0,
    idClient: 0,
    name: "",
    startSlot1: "",
    endSlot1: "",
    startSlot2: "",
    endSlot2: "",
    days: "",
    state: false
  });

  // Fonction pour convertir une chaîne de jours en tableau
  const convertDaysStringToArray = (daysString: string): string[] => {
    return daysString ? daysString.split(',').map(day => day.trim()) : [];
  };

  // Fonction pour convertir un tableau de jours en chaîne avec des virgules
  const convertDaysArrayToString = (daysArray: string[]): string => {
    return daysArray.join(','); // Utiliser une virgule ici
  };

  // Gestion des cases à cocher pour les jours
  const handleDaysChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    const currentDaysArray = convertDaysStringToArray(newSchedule.days);

    const updatedDaysArray = checked
      ? [...currentDaysArray, value] // Ajouter le jour s'il est coché
      : currentDaysArray.filter(day => day !== value); // Retirer le jour s'il est décoché

    setNewSchedule(prevSchedule => ({
      ...prevSchedule,
      days: convertDaysArrayToString(updatedDaysArray) // Stocker la chaîne formatée avec des virgules
    }));
  };

  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();

  // Gestion des autres champs du formulaire
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const timeWithSeconds = value.length === 5 ? `${value}:00` : value;

    setNewSchedule(prevSchedule => ({
      ...prevSchedule,
      [name]: timeWithSeconds
    }));
  };

  // Soumission du formulaire
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/schedule', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newSchedule)
      });

      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un plan d'appel", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newSchedule.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="startSlot1">
        <Form.Label>Créneau horaire 1 ouverture</Form.Label>
        <Form.Control
          type="time"
          name="startSlot1"
          value={newSchedule.startSlot1}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="endSlot1">
        <Form.Label>Créneau horaire 1 fermeture</Form.Label>
        <Form.Control
          type="time"
          name="endSlot1"
          value={newSchedule.endSlot1}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="startSlot2">
        <Form.Label>Créneau horaire 2 ouverture</Form.Label>
        <Form.Control
          type="time"
          name="startSlot2"
          value={newSchedule.startSlot2}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="endSlot2">
        <Form.Label>Créneau horaire 2 fermeture</Form.Label>
        <Form.Control
          type="time"
          name="endSlot2"
          value={newSchedule.endSlot2}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="days">
        <Form.Label>Jours</Form.Label>
        <div>
          {["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"].map((day) => (
            <Form.Check
              inline
              type="checkbox"
              label={day}
              name="days"
              value={day}
              checked={convertDaysStringToArray(newSchedule.days).includes(day)}
              onChange={handleDaysChange}
              key={day}
            />
          ))}
        </div>
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newSchedule.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewSchedule({
              ...newSchedule,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddScheduleForm;
